import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import colors from "@/utils/colors";
import ClearIcon from "@/components/icon/ClearIcon.vue";
import TrashIcon from "@/components/icon/TrashIcon.vue";
import CheckIcon from "@/components/icon/CheckIcon.vue";
import Menu from "@/components/icon/Menu.vue";
import Close from "@/components/icon/Close.vue";
import UpSm from "@/components/icon/UpSm.vue";
import DownSm from "@/components/icon/DownSm.vue";
import RightSm from "@/components/icon/RightSm.vue";
import ChevronDown from "@/components/icon/ChevronDown.vue";
import RightArrow from "@/components/icon/RightArrow.vue";
import PlusIcon from "@/components/icon/PlusIcon.vue";
import MinusIcon from "@/components/icon/MinusIcon.vue";
import PlusCircle from "@/components/icon/PlusCircle.vue";
import CheckboxBlank from "@/components/icon/CheckboxBlank.vue";
import CheckboxChecked from "@/components/icon/CheckboxChecked.vue";
import CheckboxDisabled from "@/components/icon/CheckboxDisabled.vue";
import CheckboxDisabledOn from "@/components/icon/CheckboxDisabledOn.vue";
import RadioBlank from "@/components/icon/RadioBlank.vue";
import RadioChecked from "@/components/icon/RadioChecked.vue";
import RadioDisabled from "@/components/icon/RadioDisabled.vue";
import RadioDisabledOn from "@/components/icon/RadioDisabledOn.vue";
import InfoCircle from "@/components/icon/InfoCircle.vue";
import AlertCircle from "@/components/icon/AlertCircle.vue";
import ConfirmCircle from "@/components/icon/ConfirmCircle.vue";
import LoadingCircle from "@/components/icon/LoadingCircle.vue";
import MultipleCircle from "@/components/icon/MultipleCircle.vue";
import CheckCircle from "@/components/icon/CheckCircle.vue";
import ResetIcon from "@/components/icon/ResetIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      clear: {
        component: ClearIcon,
      },
      trash: {
        component: TrashIcon,
      },
      check: {
        component: CheckIcon,
      },
      menu: {
        component: Menu,
      },
      close: {
        component: Close,
      },
      "plus-circle": {
        component: PlusCircle,
      },
      "up-sm": {
        component: UpSm,
      },
      "down-sm": {
        component: DownSm,
      },
      "right-sm": {
        component: RightSm,
      },
      "chevron-down": {
        component: ChevronDown,
      },
      "right-arrow": {
        component: RightArrow,
      },
      plus: {
        component: PlusIcon,
      },
      minus: {
        component: MinusIcon,
      },
      "checkbox-blank": {
        component: CheckboxBlank,
      },
      "checkbox-checked": {
        component: CheckboxChecked,
      },
      "checkbox-disabled": {
        component: CheckboxDisabled,
      },
      "checkbox-disabled-on": {
        component: CheckboxDisabledOn,
      },
      "radio-blank": {
        component: RadioBlank,
      },
      "radio-checked": {
        component: RadioChecked,
      },
      "radio-disabled": {
        component: RadioDisabled,
      },
      "radio-disabled-on": {
        component: RadioDisabledOn,
      },
      "info-circle": {
        component: InfoCircle,
      },
      "alert-circle": {
        component: AlertCircle,
      },
      "confirm-circle": {
        component: ConfirmCircle,
      },
      "check-circle": {
        component: CheckCircle,
      },
      "loading-circle": {
        component: LoadingCircle,
      },
      "multiple-circle": {
        component: MultipleCircle,
      },
      "reset-icon": {
        component: ResetIcon,
      },
    },
  },
  theme: {
    dark: false,
    themes: {
      light: colors,
      dark: colors,
    },
  },
  breakpoint: {
    mobileBreakPoint: "sm",
  },
});
