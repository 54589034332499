import globalStore from "@/store";
export default {
  setProductReviews(state, result) {
    state.productReviews.lastKey = result.lastKey;
    state.productReviews.items = [
      ...state.productReviews.items,
      ...result.results,
    ];
    if (result.stats) {
      state.productReviews.stats = {
        ...state.productReviews.stats,
        ...result.stats,
      };
    }
  },
  clearProductReviews(state) {
    state.productReviews.items = [];
    state.productReviews.lastKey = "";
  },
  clearProductReviewsStat(state) {
    state.productReviews.stats = {
      rating1: 0,
      rating2: 0,
      rating3: 0,
      rating4: 0,
      rating5: 0,
      ratingAllCnt: 0,
    };
  },
  updateProductReview(state, result) {
    const product = globalStore.getters["activities/getRoomProduct"];
    if (product?.review) {
      if (product?.review?.id === result.id) {
        product.review = { ...result };
      }
    }
    state.productReviews.items = state.productReviews.items.map((item) => {
      if (item.id === result.id) {
        return result;
      } else {
        return item;
      }
    });
  },
  updateOneReview(state, result) {
    state.productReviews.items = state.productReviews.items.map((item) => {
      if (item.id === result.id) {
        return { ...item, ...result };
      } else {
        return item;
      }
    });
  },
  deleteOneReview(state, reviewId) {
    const idx = state.productReviews.items?.findIndex(
      (old) => old.id === reviewId
    );
    if (idx > -1) {
      state.productReviews.items.splice(idx, 1);
    }
  },
  setProductNotices(state, result) {
    state.productNotices.lastKey = result.lastKey;
    state.productNotices.items = [
      ...state.productNotices.items,
      ...result.results,
    ];
  },
  clearProductNotices(state) {
    state.productNotices.items = [];
    state.productNotices.lastKey = "";
  },
  setProductNotice(state, notice) {
    state.productNotice = notice;
  },
  clearReplies(state) {
    state.replies.lastKey = "";
    state.replies.items = [];
  },
  setReplies(state, result) {
    const setting = result.results.map((reply) => {
      return { ...reply, underReplies: [], underLastKey: "" };
    });
    state.replies.lastKey = result.lastKey;
    state.replies.items = [...state.replies.items, ...setting];
  },
  removeReply(state, id) {
    state.replies.items.forEach((reply, idx) => {
      if (reply.id === id) {
        if (reply.underReplies?.length > 0 || reply.replyCnt > 0) {
          state.replies.items = state.replies.items.map((target) => {
            if (target === reply) {
              return { ...reply, conts: "삭제된 댓글입니다.", tempDelYn: true };
            } else {
              return target;
            }
          });
        } else {
          state.replies.items.splice(idx, 1);
        }
      } else if (reply.underReplies) {
        reply.underReplies.forEach((under, index) => {
          if (under.id === id) {
            reply.underReplies.splice(index, 1);
            reply.replyCnt--;
          }
        });
      }
    });
  },
  updateReply(state, reply) {
    state.replies.items = state.replies.items?.map((el) => {
      if (el.id === reply.id) {
        return { ...el, ...reply };
      }
      if (el.underReplies?.length > 0) {
        const underReplies = el.underReplies.map((under) => {
          if (under.id === reply.id) {
            return reply;
          }
          return under;
        });
        return {
          ...el,
          underReplies,
        };
      }
      return el;
    });
  },
  updateReplies(state, reply) {
    reply["underReplies"] = [];
    state.replies.items = [reply, ...state.replies.items];
  },
  updateReplyCnt(state, { cnt = 0, articleObjType }) {
    if (articleObjType === "PRODUCT_NOTICE_REPLY") {
      if (!state.productNotice) {
        return;
      }
      const prevCnt = state.productNotice.replyCnt || 0;
      const replyCnt = prevCnt + cnt;
      if (replyCnt < 0) {
        return;
      }
      state.productNotice = { ...state.productNotice, replyCnt };
    } else if (articleObjType === "CAMPUS_NOTICE_REPLY") {
      if (!state.campusNotice) {
        return;
      }
      const prevCnt = state.campusNotice.replyCnt || 0;
      const replyCnt = prevCnt + cnt;
      if (replyCnt < 0) {
        return;
      }
      state.campusNotice = { ...state.campusNotice, replyCnt };
    }
  },
  clearUnderReplies(state, upperId) {
    state.replies.items.forEach((item) => {
      if (item.id === upperId) {
        item.underReplies = [];
        item.underLastKey = "";
      }
    });
  },
  updateUnderReplies(state, reply) {
    state.replies.items.forEach((item) => {
      if (item.id === reply.upperId) {
        item.underReplies = [reply, ...item.underReplies];
        item.replyCnt++;
      }
    });
  },
  setUnderReplies(state, result) {
    state.replies.items = state.replies.items.map((item) => {
      if (item.id === result.upperId) {
        item.underReplies = [
          ...item.underReplies,
          ...result.underReplies.results,
        ];
        item.underLastKey = result.underReplies.lastKey;
      }
      return item;
    });
  },
  setCampusNotices(state, result) {
    state.campusNotices.lastKey = result.lastKey;
    state.campusNotices.items = [
      ...state.campusNotices.items,
      ...(result.results || []),
    ];
  },
  clearCampusNotice(state) {
    state.campusNotice = null;
  },
  clearCampusNotices(state) {
    state.campusNotices.items = [];
    state.campusNotices.lastKey = "";
  },
  setCampusNotice(state, notice) {
    state.campusNotice = notice;
  },
  setFeedBackList(state, result) {
    state.feedBackList.lastKey = result.lastKey;
    state.feedBackList.items = [...state.feedBackList.items, ...result.results];
  },
  clearFeedBackList(state) {
    state.feedBackList.items = [];
    state.feedBackList.lastKey = "";
  },
  setFeedBack(state, result) {
    state.feedBack = result;
  },
  clearFeedBack(state) {
    state.feedBack = null;
  },
  setFeedbackViewMode(state, mode = "post") {
    state.feedbackViewMode = mode;
  },
  setFeedbackCode(state, code) {
    state.feedbackCode = code;
  },
  updateCampusNotices(state, result) {
    state.campusNotices.lastKey = result?.lastKey;
    state.campusNotices.items = result.items;
  },
  setAvailableReviews(state, result) {
    state.availableReviews = result;
  },
  setCampusNoticeCategories(state, categories = []) {
    state.campusNoticeCategories = structuredClone(categories);
  },
};
