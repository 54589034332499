import globalStore from "@/store";

export default {
  setCampusInfo: (state, campusInfo) => {
    const { campusId, member } = campusInfo;
    if (state.campusInfo?.campusId === campusId) {
      state.campusInfo = { ...state.campusInfo, ...campusInfo };
    } else {
      state.campusInfo = { ...campusInfo };
    }
    globalStore.commit("members/updateMemberItem", member);
  },
  replaceCampusInfo: (state, campusInfo) => {
    const { campusId, member } = campusInfo;
    state.campusInfo = { ...campusInfo };
    globalStore.commit("members/setMemberItem", member);
  },
  setDesigns: (state, { campusId, designs = [] }) => {
    if (state.campusInfo?.campusId === campusId) {
      state.designs = mergeData(state.designs, designs);
    } else {
      state.designs = [...designs];
    }
  },
  updateCampusInfo: (state, campusInfo) => {
    const { campusId } = campusInfo;
    state.campusInfo = { ...state.campusInfo, ...campusInfo };
  },
  clearCampusInfo: (state) => {
    state.campusInfo = null;
    globalStore.commit("members/clearMemberItem");
  },
  setUserCampusList: (state, userCampusList) => {
    state.userCampusList = {
      ...userCampusList,
      all: [
        ...(userCampusList.owners || []),
        ...(userCampusList.requests || []),
        ...(userCampusList.joins || []),
      ],
    };
  },
  setUserAllCampusList: (state, allList) => {
    state.userAllCampusList = { ...allList };
  },
  setDashboard: (state, dashboard) => {
    if (!dashboard) return;
    state.campusInfo = {
      ...state.campusInfo,
      ...dashboard,
    };
  },
  setCertificate: (state, certificate) => {
    state.certificate = { ...certificate };
  },
  clearCertificate: (state) => {
    state.certificate = null;
  },
  setMailAccounts: (state, site) => {
    state.campusInfo = {
      ...state.campusInfo,
      SITE: site,
    };
  },
  clearMailVerification: (state) => {
    state.campusInfo.SITE.mailAccounts = [];
  },
  setMailDomainAccounts: (state, site) => {
    state.campusInfo = {
      ...state.campusInfo,
      SITE: site,
    };
  },
  setSubscription(state, data) {
    state.subscription = data;
  },
  updateSubscription(state, data) {
    state.subscription = { ...state.subscription, ...data };
  },
  setTempVolumes(state, volumes = 0) {
    state.tempVolumes = volumes;
  },
};

function mergeData(existingData, newData) {
  const existingDataMap = new Map(
    existingData.reduce((acc, cur) => {
      if (cur.menuType === "DROPDOWN" && cur.items) {
        cur.items.forEach((item) => {
          if (item.slug) {
            acc.push([item.slug, item]);
          }
        });
      }
      if (cur.slug && cur.menuType !== "CUSTOM") {
        acc.push([cur.slug, cur]);
      }
      return acc;
    }, [])
  );

  return newData.map((newItem) => {
    if (newItem.menuType === "DROPDOWN" && newItem.items) {
      const items = newItem.items.map((item) => {
        if (
          !!item.slug &&
          item.menuType !== "CUSTOM" &&
          !item.blocks &&
          existingDataMap.has(item.slug) &&
          existingDataMap.get(item.slug).blocks
        ) {
          return { ...item, blocks: existingDataMap.get(item.slug).blocks };
        }
        return item;
      });
      return { ...newItem, items };
    }
    if (
      !!newItem.slug &&
      newItem.menuType !== "CUSTOM" &&
      !newItem.blocks &&
      existingDataMap.has(newItem.slug) &&
      existingDataMap.get(newItem.slug).blocks
    ) {
      return { ...newItem, blocks: existingDataMap.get(newItem.slug).blocks };
    }
    return newItem;
  });
}
