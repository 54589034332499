<script setup>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router/composables";
import ChannelTalk from "@/utils/ChannelService.js";
import { useAuth } from "@/hooks/useAuth";
import MenuButton from "./MenuButton.vue";
import PoincampusLogo from "./PoincampusLogo.vue";
import PoinHeaderMobileNavigation from "./PoinHeaderMobileNavigation.vue";
import NoUserProfile from "./NoUserProfile.vue";
import UserProfile from "@/components/poin-ui/UserProfile.vue";
import NotificationIcon from "@/components/poin-ui/NotificationIcon.vue";

const { isAuthenticated } = useAuth();
const expand = ref(false);

const route = useRoute();
const currentPath = computed(() => route.fullPath);

const openMenu = () => {
  expand.value = !expand.value;

  if (expand.value) {
    ChannelTalk.hideChannelButton();
  } else {
    ChannelTalk.showChannelButton();
  }
};

watch(
  currentPath,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      expand.value = false;
    }
  },
  {
    deep: true,
  }
);
</script>

<template>
  <header class="header__container">
    <div class="header__wrap">
      <MenuButton @open="openMenu" />
      <PoincampusLogo />
      <div v-if="isAuthenticated" class="btns--right">
        <NotificationIcon />
        <UserProfile />
      </div>
      <NoUserProfile v-else class="btns--right" />
    </div>

    <v-fade-transition>
      <div
        @click="expand = false"
        v-if="expand"
        style="
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          height: 100dvh;
          z-index: 999;
          background: rgba(0, 0, 0, 0.5);
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          "
        >
          <v-slide-x-transition>
            <PoinHeaderMobileNavigation v-show="expand" @click.native.stop />
          </v-slide-x-transition>
        </div></div
    ></v-fade-transition>
  </header>
</template>

<style lang="scss" scoped>
.header__container {
  width: 100%;
  height: var(--header-height);
  padding: 0 var(--gutter);
  background-color: $white;
  box-shadow: 0 1px 0 0 $gray3;
  z-index: var(--header-zIndex);
  top: 0;
  left: 0;
  position: sticky;
  .header__wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.btns--right {
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  right: 0;
}
</style>
