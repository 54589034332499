import { defineStore } from "pinia";
import { ref, reactive } from "vue";
import { useAlert } from "@/stores/alert";
import { apiV2 as apis, useStore } from "@/store";
import { useI18n } from "vue-i18n-composable";
import {
  poinOldPostedUrl,
  poinLinkLog,
  poinReferrer,
} from "@/utils/cookie-utils";
import { getSearchParam, removeSearchParam, } from "@/utils/url-utils";

export const useHistoryStore = defineStore("history", () => {
  const store = useStore();
  const alert = useAlert();
  const { t } = useI18n();

  const consents = ref([]);

  async function getPartnersConsent() {
    const result = await apis.histories.getPartnersConsent({
      campusId: store.getters["campuses/getCampusUuid"],
      memberId: store.getters["members/getMemberId"],
    });
    if (result?.success) {
      consents.value = result.data || [];
    } else {
      let title = "요청 실패";
      let msg = "";
      switch (result.code) {
        case "ACCESS_DENIED":
          msg = t("error.access");
          break;
        case "UNAUTHORIZE":
          msg = t("error.authorize");
          break;
        default:
          msg = t("error.default");
      }

      alert.open({
        title,
        msg,
      });
    }
    return result;
  }

  async function gerPartnerLinkLog(params = {}) {
    const {
      startDate,
      endDate,
      partnerId = store.getters["members/getMemberId"],
    } = params;

    if (!partnerId) {
      return { success: false };
    }

    const result = await apis.histories.getPartnerLinkLogs({
      campusId: store.getters["campuses/getCampusUuid"],
      memberId: store.getters["members/getMemberId"],
      partnerId,
      startDate,
      endDate,
    });

    return result;
  }

  async function postPartnerLinkLogs(route) {
    if (!route) return;
    // 파트너 코드 링크로 접속한 경우에만 호출.
    if (!getSearchParam("pcd")) return;
    // 기존에 호출했던 링크라면 추가 호출하지 않는다.
    // 쿠키는 5분동안 유효하도록 설정되어 있음.
    const oldUrl = poinOldPostedUrl.get();
    if (oldUrl && oldUrl === window.location.href) return;

    const isStaff = store.getters["members/isStaff"];
    if (isStaff) return;

    let linkType, linkTargetId, linkTargetName, slug;
    if (route.meta.productDetail) {
      // 프로덕트 상세인 경우. 프로덕트 상세 컴포넌트에서 호출한다고 가정한다.
      const product = store.getters["products/getProduct"];
      if (!product) return;
      linkType = "PRODUCT";
      linkTargetId = product.id;
      linkTargetName = product.name;
    } else {
      slug = route.meta?.slug || route.params?.slug;
      linkType = "PAGE";
      linkTargetId = slug || window.location.href;
      linkTargetName =
        store.getters["campuses/getMenuBySlug"](slug)?.name || route.path;
    }

    const result = await apis.histories.postPartnersLink({
      url: window.location.href,
      referrer: poinReferrer.get(),
      linkType,
      linkTargetId,
      linkTargetName,
    });

    if (result.success) {
      poinOldPostedUrl.set();
      poinLinkLog.set(result.data);
    }
    // 이후 추가 호출하지 않도록 하기 위해 pcd 쿼리스트링을 제거한다
    route.query.pcd = undefined;
    removeSearchParam("pcd");
  }

  return {
    consents,
    getPartnersConsent,
    gerPartnerLinkLog,
    postPartnerLinkLogs,
  };
});
