<script setup>
import { useAuth } from "@/hooks/useAuth";
import CampusLogo from "@/components/layout/header/CampusLogo.vue";
import CampusHeaderMobileNavigation from "./CampusHeaderMobileNavigation.vue";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router/composables";
import { useStore } from "@/store";
import { useCampusStore } from "@/stores/campus";
import NotificationIcon from "@/components/poin-ui/NotificationIcon.vue";

const { isAuthenticated } = useAuth();
const emit = defineEmits(["showAside"]);
const expand = ref(false);

const route = useRoute();
const currentPath = computed(() => route.fullPath);

const store = useStore();
const campusStore = useCampusStore();
const styles = computed(() => {
  return campusStore.styles;
});

const domain = computed(() => store.getters["campuses/getCampusDomain"]);
const lastAccCont = computed(() => store.getters["members/getLastAccCont"]);
const isStaff = computed(() => store.getters["members/isStaff"]);
const bgColor = computed(() => styles.value?.headerStyles?.backgroundColor);
const color = computed(() => styles.value?.headerStyles?.color);
const accentColor = computed(() => styles.value?.headerStyles?.accentColor);

watch(
  currentPath,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      expand.value = false;
    }
  },
  {
    deep: true,
  }
);
</script>

<template>
  <header class="header__container">
    <div class="header__wrap">
      <router-link
        :to="{ name: 'campus-home', params: route.params }"
        class="d-flex mr-auto"
      >
        <CampusLogo />
      </router-link>
      <NotificationIcon v-if="isAuthenticated" />
    </div>
    <v-expand-transition>
      <CampusHeaderMobileNavigation v-if="expand" />
    </v-expand-transition>
  </header>
</template>

<style lang="scss" scoped>
.header__container {
  width: 100%;
  height: var(--header-height);
  background-color: v-bind(bgColor);
  box-shadow: 0 1px 0 0 $gray3;
  z-index: var(--header-zIndex);
  top: 0;
  left: 0;
  position: sticky;
  .header__wrap {
    padding: 0 var(--gutter);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
</style>
