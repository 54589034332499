import { endpointV2 as endpoint } from "@/config";

// 클래스 결제 정보 전체 조회 & 프로덕트 결제 내역 엑셀다운로드 histories/sales/
export async function getUserPaymentsHistories({
  campusId,
  memberId,
  basis,
  customerName,
  limit,
  paymentStatus,
  order,
  classId,
  lastKey,
  excelYn,
  sDate,
  eDate,
}) {
  let params = {};
  if (basis) {
    params.basis = basis;
  }
  if (memberId) {
    params.memberId = memberId;
  }
  if (customerName) {
    params.customerName = customerName;
  }
  if (limit) {
    params.limit = limit;
  }
  if (paymentStatus) {
    params.paymentStatus = paymentStatus;
  }
  if (customerName) {
    params.customerName = customerName;
  }
  if (classId) {
    params.classId = classId;
  }
  if (order) {
    params.order = order;
  }
  if (lastKey) {
    params.lastKey = lastKey;
  }
  if (excelYn) {
    params.excelYn = excelYn;
  }
  if (excelYn) {
    params.excelYn = excelYn;
  }
  if (sDate) {
    params.sDate = sDate;
  }
  if (eDate) {
    params.eDate = eDate;
  }
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/${campusId}`,
    params,
  });
}
// 클래스 결제 정보 요약 histories/summary/
export async function getPaymentSummary({
  campusId,
  memberId,
  sDate,
  eDate,
  productId,
}) {
  let params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(productId && { productId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/summary/${campusId}`,
    params,
  });
}
//판매 프로덕트 결제 내역 결제 취소
export async function deletePaymentHistories({ campusId, id, memberId }) {
  const data = {};
  if (campusId) {
    data.campusId = campusId;
  }
  if (id) {
    data.id = id;
  }
  if (memberId) {
    data.memberId = memberId;
  }
  return this.getMethod("delete", true, {
    url: `${endpoint.payments}/histories/`,
    data,
  });
}

// 판매클래스 일반 결제 승인 요청
export async function reqClsPay({
  classId,
  userId,
  tran_cd,
  enc_data,
  enc_info,
  good_name,
  good_mny,
}) {
  const data = {
    classId,
    userId,
    tran_cd,
    enc_data,
    enc_info,
    good_name,
    good_mny,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.payments}/sales`,
    data,
  });
}

// 모바일 일반 결제 거래 등록, 모바일 배치키 발급 거래 등록.
export async function reqRegPay({
  ordr_idxx,
  good_mny,
  good_name,
  pay_method,
  Ret_URL,
  escw_used,
  user_agent,
  batchYn,
  subsYn,
}) {
  const data = {
    ordr_idxx,
    good_mny,
    good_name,
    pay_method,
    Ret_URL,
    escw_used,
    user_agent,
    batchYn,
    ...(subsYn && { subsYn }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.payments}/kcp/register`,
    data,
  });
}
// 카드 등록 배치키 발급
export async function reqBatchIssue({
  userId,
  tran_cd,
  enc_data,
  enc_info,
  card_mask_no,
  subsYn,
}) {
  const data = {
    userId,
    tran_cd,
    enc_data,
    enc_info,
    card_mask_no,
    ...(subsYn && { subsYn }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.payments}/batch/issue/pc`,
    data,
  });
}

/* cards사용자의 카드 목록 조회 */
export async function getCards({ userId }) {
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/cards`,
    params: {
      userId,
    },
  });
}

/* cards 카드 정보 수정 */
export async function putCards({ id, userId, remark, color, subsYn, targets }) {
  return this.getMethod("put", true, {
    url: `${endpoint.payments}/cards`,
    data: {
      id,
      userId,
      remark,
      color,
      ...(subsYn && { subsYn }),
      ...(targets?.length > 0 && { targets }),
    },
  });
}

/* cards 카드 삭제 */
export async function deleteCards({ id, userId }) {
  return this.getMethod("delete", true, {
    url: `${endpoint.payments}/batch`,
    data: {
      id,
      userId,
    },
  });
}

// 캠퍼스관리 > 요금 플랜 > 요금 플랜 결제이력 조회
export async function getPlanHistories({
  campusId,
  memberId,
  paymentStatus,
  planHistoryType,
  planType,
  sDate,
  eDate,
  order = "DESC",
  limit,
  lastKey,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(paymentStatus && { paymentStatus }),
    ...(planHistoryType && { planHistoryType }),
    ...(planType && { planType }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(order && { order }),
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/plans/${campusId}`,
    params,
  });
}
// 결제 내역 단건 조회
export async function getCheckoutHistory({ campusId, memberId, id }) {
  const params = {
    campusId,
    memberId,
    id,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/${campusId}/${id}`,
    params,
  });
}
// 무료 판매클래스 결제 요청
export async function reqSalesFree({ classId, userId, memberId }) {
  const data = {
    classId,
    userId,
    memberId,
  };
  return this.getMethod("post", true, {
    url: `${endpoint.payments}/sales/free`,
    data,
  });
}
// 무료/유료 판매 프로덕트 > 판매 프로덕트 결제하기
export async function postProductPayment({
  campusId,
  memberId,
  userId,
  productId,
  promotionId,
  customerName,
  good_name,
  enc_data,
  enc_info,
  tran_cd,
  good_mny,
  ordr_idxx,
  contact,
  email,
  testYn,
  paymentWay,
  priceId,
  couponId,
  cardId,
  subscriptionYn,
  quota,
  partnerCode,
  referrer,
  linkPk,
  linkId,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(productId && { productId }),
    ...(promotionId && { promotionId }),
    ...(customerName && { customerName }),
    ...(good_name && { good_name }),
    ...(enc_data && { enc_data }),
    ...(enc_info && { enc_info }),
    ...(tran_cd && { tran_cd }),
    ...(good_mny != null && { good_mny }),
    ...(ordr_idxx && { ordr_idxx }),
    ...(contact && { contact }),
    ...(email && { email }),
    ...(testYn && { testYn }),
    ...(paymentWay && { paymentWay }),
    ...(priceId && { priceId }),
    ...(couponId && { couponId }),
    ...(cardId && { cardId }),
    ...(subscriptionYn && { subscriptionYn }),
    ...(quota && { quota }),
    ...(partnerCode && { partnerCode }),
    ...(referrer && { referrer }),
    ...(linkPk && { linkPk }),
    ...(linkId && { linkId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.payments}/products/sales`,
    data,
  });
}
// 예약 프로덕트 결제 이후 예약 추가정보 업데이트 요청
export async function putProductAdditional({
  campusId,
  paymentId,
  custAdditionals,
}) {
  const data = {
    campusId,
    paymentId,
    custAdditionals,
  };
  return this.getMethod("put", true, {
    url: `${endpoint.payments}/products/sales`,
    data,
  });
}
// 기타결제 승인 처리
export async function postEtcProductPayment({
  campusId,
  memberId,
  userId,
  productId,
  targetIds,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(productId && { productId }),
    ...(targetIds && { targetIds }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.payments}/products/etc`,
    data,
  });
}
// 마이페이지 > 내 구매내역 > 즉시 취소 및 취소 요청
export async function putCancelProductPayment({
  paymentId,
  campusId,
  memberId,
  userId,
  remark,
  cancelReason,
  paymentStatus,
  refund,
  mod_depositor,
  mod_account,
  mod_bankcode,
}) {
  const data = {
    ...(paymentId && { paymentId }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(remark && { remark }),
    ...(cancelReason && { cancelReason }),
    ...(paymentStatus && { paymentStatus }),
    ...(refund && { refund }),
    ...(mod_depositor && { mod_depositor }),
    ...(mod_account && { mod_account }),
    ...(mod_bankcode && { mod_bankcode }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.payments}/products/cancel`,
    data,
  });
}
// 캠퍼스관리 > 프로덕트 > 결제 내역 > 관리자 취소 처리
export async function cancelProductPaymentStaff({
  paymentId,
  campusId,
  memberId,
  userId,
  remark,
  cancelReason,
  paymentStatus,
  refund,
  mod_depositor,
  mod_account,
  mod_bankcode,
}) {
  const data = {
    ...(paymentId && { paymentId }),
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(remark && { remark }),
    ...(cancelReason && { cancelReason }),
    ...(paymentStatus && { paymentStatus }),
    ...(refund && { refund }),
    ...(mod_depositor && { mod_depositor }),
    ...(mod_account && { mod_account }),
    ...(mod_bankcode && { mod_bankcode }),
  };
  return this.getMethod("delete", true, {
    url: `${endpoint.payments}/products/cancel`,
    data,
  });
}
// 캠퍼스관리 > 회원 > 회원상세 > 결제 내역 조회
export async function getUserDetailPayments({
  campusId,
  memberId,
  productId,
  promotionId,
  userId,
  keyword,
  limit,
  paymentStatus,
  order,
  basis,
  lastKey,
  inPaymentStatus,
  productType,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(promotionId && { promotionId }),
    ...(userId && { userId }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(paymentStatus && { paymentStatus }),
    ...(order && { order }),
    ...(basis && { basis }),
    ...(lastKey && { lastKey }),
    ...(inPaymentStatus && { inPaymentStatus }),
    ...(productType && { productType }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/members/${userId}`,
    params,
  });
}
// 캠퍼스 관리 > 회원 > 회원상세 요약
export async function getUserDetailPaymentSummary({
  campusId,
  memberId,
  userId,
  basis,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(basis && { basis }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/summary/members/${userId}`,
    params,
  });
}
// 캠퍼스관리 > 프로덕트 > 결제 내역 엑셀 다운로드 (판매 > 전체 결제 내역, 프로덕트 관리 > 결제 내역, 예약 관리 > 결제 내역)
export async function getProductPaymentsExcel({
  campusId,
  memberId,
  productId,
  sDate,
  eDate,
  excelType,
  fileName,
  partnerId,
  basis,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(fileName && { fileName }),
    ...(partnerId && { partnerId }),
    ...(basis && { basis }),
    excelType,
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/${campusId}/excel`,
    params,
  });
}
// 캠퍼스관리 > 프로덕트 > 결제 내역 메뉴 조회,
export async function getProductPaymentsHistories({
  campusId,
  memberId,
  userId,
  productId,
  promotionId,
  keyword,
  limit,
  paymentStatus,
  basis,
  order,
  lastKey,
  inPaymentStatus,
  paymentWay,
  optionName,
  priceName,
  productType,
  discountType,
  originCouponId,
  groupId,
  sDate,
  eDate,
  sAmount,
  eAmount,
  purchaseType,
  partnerId,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(userId && { userId }),
    ...(productId && { productId }),
    ...(promotionId && { promotionId }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(paymentStatus && { paymentStatus }),
    ...(basis && { basis }),
    ...(order && { order }),
    ...(lastKey && { lastKey }),
    ...(inPaymentStatus?.length > 0 && { inPaymentStatus }),
    ...(paymentWay && { paymentWay }),
    ...(optionName && { optionName }),
    ...(priceName && { priceName }),
    ...(productType && { productType }),
    ...(discountType && { discountType }),
    ...(originCouponId && { originCouponId }),
    ...(groupId && { groupId }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(sAmount && { sAmount }),
    ...(eAmount && { eAmount }),
    ...(purchaseType && { purchaseType }),
    ...(partnerId && { partnerId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/${campusId}`,
    params,
  });
}
// 캠퍼스 관리 > 프로덕트 > 결제 내역 요약 조회
export async function getProductPaymentsSummary({
  campusId,
  memberId,
  productId,
  sDate,
  eDate,
  inPaymentStatus,
  partnerId,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(inPaymentStatus && { inPaymentStatus }),
    ...(partnerId && { partnerId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/summary/${campusId}`,
    params,
  });
}
//(사용자) 결제 내역 단건 조회
export async function getUserCheckoutHistory({ campusId, userId, paymentId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(userId && { userId }),
    ...(paymentId && { paymentId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/users/${userId}/${paymentId}`,
    params,
  });
}
// 캠퍼스관리 > 회원 > 회원상세 > 결제 내역 > 프로모션, 프로덕트 드롭다운 목록 조회
export async function getUserPaymentDropDown({ campusId, memberId, userId }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };

  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/dropdown/members/${userId}/`,
    params,
  });
}
// 마이페이지 > 내 구매내역
export async function getUserPurchases({
  userId,
  campusId,
  keyword,
  paymentStatus,
  sDate,
  eDate,
  order,
  limit,
  lastKey,
  productType,
  inPaymentStatus,
}) {
  const params = {
    ...(userId && { userId }),
    ...(campusId && { campusId }),
    ...(keyword && { keyword }),
    ...(paymentStatus && { paymentStatus }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(order && { order }),
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
    ...(productType && { productType }),
    ...(inPaymentStatus && { inPaymentStatus }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/sales/users/${userId}`,
    params,
  });
}
// 캠퍼스 관리 > 예약 관리 > 결제내역 > 옵션 필터 목록 조회
export async function getReservationOptions({
  campusId,
  memberId,
  productId,
  basis,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(basis && { basis }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/reservation/options`,
    params,
  });
}
// 캠퍼스관리 > 판매관리 > 정산용 내역 데이터 조회
export async function getPaymentsHistorySettlement({
  campusId,
  memberId,
  basis,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(basis && { basis }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/settlements/details`,
    params,
  });
}

export async function getSettlements({
  campusId,
  memberId,
  basis,
  productType,
  purchaseType,
  keyword,
  order,
  limit,
  lastKey,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(basis && { basis }),
    ...(productType && { productType }),
    ...(purchaseType && { purchaseType }),
    ...(keyword && { keyword }),
    ...(order && { order }),
    ...(limit && { limit }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/settlements`,
    params,
  });
}

export async function getSettlementsSummaries({ campusId, memberId, basis }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(basis && { basis }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/settlements/summaries`,
    params,
  });
}

export async function getSettlementsDetail({
  campusId,
  memberId,
  productId,
  paymentId,
  refundYn,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(refundYn && { refundYn }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/settlements/${paymentId}`,
    params,
  });
}
export async function getSubsPaymentsHistories({
  productMemberId,
  memberId,
  limit = 20,
  campusId,
  order = "DESC",
  lastKey,
}) {
  const params = {
    campusId,
    memberId,
    limit,
    order,
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/subs/${productMemberId}`,
    params,
  });
}

export async function postSubscriptionRetry({
  campusId,
  memberId,
  productId,
  custMemberId,
}) {
  const data = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(productId && { productId }),
    ...(custMemberId && { custMemberId }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.payments}/subscription/products/retry`,
    data,
  });
}
// 판매프로덕트 정기구매 해지 or 해지 취소
export async function postSubscriptionChange({
  campusId,
  memberId,
  productId,
  subsStatus,
  targetMemberIds,
  cancelReason,
}) {
  const data = {
    targetMemberIds,
    subsStatus,
    productId,
    campusId,
    memberId,
    ...(cancelReason && { cancelReason }),
  };
  return this.getMethod("post", true, {
    url: `${endpoint.payments}/subscription/products/change`,
    data,
  });
}
export async function getSettlementsPartners({
  campusId,
  memberId,
  partnerId = "ALL",
  basis,
  keyword,
  limit,
  order,
  settlementStatus,
  lastKey,
}) {
  const params = {
    partnerId,
    basis,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(order && { order }),
    ...(settlementStatus && { settlementStatus }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/settlements/partners`,
    params,
  });
}

export async function getSettlementsPartnersSummaries({
  campusId,
  memberId,
  partnerId = "ALL",
  basis,
}) {
  const params = {
    partnerId,
    basis,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/settlements/partners/summaries`,
    params,
  });
}

export async function getSettlementsPartnersDetail({
  campusId,
  memberId,
  productId,
  paymentId,
  partnerId,
}) {
  const params = {
    productId,
    partnerId,
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(paymentId && { paymentId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/settlements/partners/${paymentId}`,
    params,
  });
}

export async function putSettlementsPartners({
  note,
  settlementStatus,
  campusId,
  ids,
  partnerId,
  basis,
  memberId,
}) {
  const data = {
    settlementStatus,
    ...(campusId && { campusId }),
    ...(ids && { ids }),
    ...(note != null && { note }),
    ...(partnerId && { partnerId }),
    ...(basis && { basis }),
    ...(memberId && { memberId }),
  };
  return this.getMethod("put", true, {
    url: `${endpoint.payments}/settlements/partners`,
    data,
  });
}
// 파트너 서비스 > 수익 리포트 요약 조회
export async function getPartnerSalesSummary({
  campusId,
  memberId,
  sDate,
  eDate,
  productId,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(productId && { productId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/partners/summary/${memberId}`,
    params,
  });
}

// 파트너 서비스 > 수익 리포트 목록 조회
export async function getPartnerSalesReport({
  campusId,
  memberId,
  productType,
  paymentStatus,
  limit,
  order,
  inPaymentStatus,
  purchaseType,
  lastKey,
  sDate,
  eDate,
  keyword,
  productId,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(productType && { productType }),
    ...(paymentStatus && { paymentStatus }),
    ...(limit && { limit }),
    ...(order && { order }),
    ...(inPaymentStatus?.length > 0 && { inPaymentStatus }),
    ...(purchaseType && { purchaseType }),
    ...(lastKey && { lastKey }),
    ...(sDate && { sDate }),
    ...(eDate && { eDate }),
    ...(keyword && { keyword }),
    ...(productId && { productId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/histories/partners/${memberId}`,
    params,
  });
}

// 파트너 > 수익 정상 내역 요약 조회
export async function getPartnersSummaries({ campusId, memberId, basis }) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(basis && { basis }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/partners/summaries/${memberId}`,
    params,
  });
}

// 파트너 > 수익 정상 내역 목록 조회
export async function getPartners({
  campusId,
  memberId,
  basis,
  keyword,
  limit,
  order,
  lastKey,
  settlementStatus,
  productType,
  purchaseType,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(basis && { basis }),
    ...(keyword && { keyword }),
    ...(limit && { limit }),
    ...(order && { order }),
    ...(settlementStatus && { settlementStatus }),
    ...(productType && { productType }),
    ...(purchaseType && { purchaseType }),
    ...(lastKey && { lastKey }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/partners/${memberId}`,
    params,
  });
}

// 파트너 > 수익 정상 내역 > 정산 내역 상세
export async function getPartnersDetail({
  campusId,
  memberId,
  paymentId,
  productId,
}) {
  const params = {
    ...(campusId && { campusId }),
    ...(memberId && { memberId }),
    ...(paymentId && { paymentId }),
    ...(productId && { productId }),
  };
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/partners/${memberId}/${paymentId}`,
    params,
  });
}

// ezwel 헤더 조회
export async function getEzwelHeader({ token, campusId, }) {
  return this.getMethod("get", true, {
    url: `${endpoint.payments}/ezwel/header`,
    params: { token, campusId, },
  });
}

// ezwel 주문정보 암호화 요청
export async function reqEzwelOrder({ campusId, orderData, tempOrderData, aesKey, }) {
  return this.getMethod("post", true, {
    url: `${endpoint.payments}/ezwel/order`,
    data: { campusId, orderData, tempOrderData, aesKey, },
  });
}
