import {
  MemberStatus,
  ProgressStatus,
  MemberChangeType,
} from "@/enums/membersEnum";
import { PayMethod, PurchaseType } from "@/enums/paymentsEnum";
import { PaymentStatus } from "@/enums/paymentsEnum";
import { QuizStatus } from "@/enums/productsEnum";
import { AskStatus } from "@/enums/askEnum";
import { getDateFormat, getDayLeft } from "@/utils/date-format";
import { comma } from "@/utils/number-format";
import CryptoJS from "crypto-js";
import { passwordSecretKey, kmsPublicKey } from "@/config";
import store from "@/store";

/**
 * 단순 텍스트에서 url을 찾아 a태그로 감싸준다.
 * html 형식의 텍스트에서는 불가.
 * @param inputText
 * @return {*|string}
 */
function linkify(inputText) {
  if (!inputText) return "";
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  // URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a class="blue--text" href="$1" target="_blank">$1</a>'
  );

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a class="blue--text" href="http://$2" target="_blank">$2</a>'
  );

  // Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a class="blue--text" href="mailto:$1">$1</a>'
  );

  return replacedText;
}

/**
 * html 형식의 문자열에서 url 형식의 텍스트 요소를 a태그로 감싸서 반환한다.
 * @param html
 * @return {string}
 */
const linkifyHtml = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;

  const walk = document.createTreeWalker(tempDiv, NodeFilter.SHOW_TEXT, null);
  while (walk.nextNode()) {
    const linkified = linkify(walk.currentNode.textContent);
    walk.currentNode.textContent = linkified;
  }

  // 삽입한 linkify 텍스트가 html 엔터티 코드로 변환되기때문에, decode 과정 필요하다.
  return decodeHTMLEntities(tempDiv.innerHTML);
};

function strip(str) {
  return str.replace(/^\s+|\s+$/g, "");
}

// 모든 공백 제거
function removeAllWhitespace(str) {
  return str.replace(/\s+/g, "");
}

/**
 * 이모지 제거
 * https://stackoverflow.com/a/69661174
 * @param str
 * @returns {string|*}
 */
const removeEmoji = (str) => {
  if (str) {
    return str.replace(
      /(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu,
      ""
    );
  }
  return "";
};

function sanitizeInput(input) {
  // 숫자와 "-"만 포함된 문자열을 만들기 위한 정규표현식
  var regex = /[0-9-]/g;

  // 입력값에서 허용된 문자 이외의 문자를 제거하고 반환
  return input?.replaceAll(" ", "")?.match(regex)?.join("") || "";
}

function phoneNumberFormat(value) {
  let changeValue = strip(value).replace(/-/g, "");
  if (changeValue.length === 10) {
    if (changeValue.substr(0, 2) === "02") {
      changeValue = changeValue.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      changeValue = changeValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
  } else if (changeValue.length === 9) {
    changeValue = changeValue.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if (changeValue.length === 8) {
    changeValue = changeValue.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else {
    changeValue = changeValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  return sanitizeInput(changeValue);
}

function bizNumberFormat(value) {
  let companyNum = value.replace(/[^0-9]/g, "");

  let tempNum = "";

  if (companyNum.length < 4) {
    return companyNum;
  } else if (companyNum.length < 6) {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    return tempNum;
  } else if (companyNum.length < 11) {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    tempNum += "-";

    tempNum += companyNum.substr(5);

    return tempNum;
  } else {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    tempNum += "-";

    tempNum += companyNum.substr(5);
    return tempNum;
  }
}

function birthFormat(value) {
  let changeValue = strip(value).replace(/-/g, "");
  changeValue = changeValue.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
  return changeValue;
}

function snsTypeFilter(type) {
  switch (type) {
    case "NONE":
      return "이메일 가입";
    case "GOOGLE":
      return "구글";
    case "NAVER":
      return "네이버";
    case "KAKAO":
      return "카카오";
    case "FACEBOOK":
      return "페이스북";
    case "APPLE":
      return "애플";
    default:
      return "";
  }
}

function encryptor(txt) {
  return txt.replace(/(\w)(\w)/g, function (a) {
    return a[0] + "*";
  });
}

function roldCdFilter(type) {
  switch (type) {
    case "A":
      return "개설자";
    case "B":
      return "운영자";
    case "C":
      return "강의자";
    case "D":
      return "회원";
    default:
      return "";
  }
}

function memberStatusName(status) {
  switch (status) {
    case "JOIN":
      return "승인됨";
    case "INVITE":
      return "초대됨";
    case "REQUEST":
      return "대기중";
    case "REJECT":
      return "거절됨";
    case "RETRY_REQUEST":
      return "재신청";
    default:
      return status;
  }
}

function memberStatusChip(status, member) {
  switch (status) {
    case "DEL": {
      if (
        [
          PaymentStatus.PAY_REJECT,
          PaymentStatus.CANCEL,
          PaymentStatus.PAY_REVERT,
        ].includes(member?.paymentStatus)
      ) {
        return { text: "결제 취소", theme: "error" };
      }
      return { text: "내보냄", theme: "gray" };
    }
    case "JOIN":
      return { text: "참여중", theme: "success" };
    case "REQUEST":
      return { text: "대기중", theme: "info" };
    case "PAY_REQUEST":
      return { text: "결제 대기", theme: "info" };
    case "REJECT":
      return { text: "거절됨", theme: "error" };
    case "RETRY_REQUEST":
      return { text: "재신청", theme: "info" };
    case "CANCEL":
      return { text: "신청 취소", theme: "gray" };
    default:
      return { text: "초대됨", theme: "success" };
  }
}
function addrSubscribeStatusChip(status, addr) {
  switch (status) {
    case "SUBSCRIBE":
      return { text: "구독 중", theme: "success", link: "'수신 거부'로 변경" };
    case "REJECT":
      return { text: "수신 거부", theme: "error", link: "'구독 중'으로 변경" };
    case "UNAVAILABLE":
      return { text: "수신 불가", theme: "gray" };
    default:
      return { text: "-", theme: "success" };
  }
}
function mktYnChip(confirm) {
  if (confirm) {
    return { text: "광고 수신동의 O", theme: "success" };
  }
  return { text: "광고 수신동의 X", theme: "error" };
}
function paymentStatusChip(status) {
  switch (status) {
    case "CANCEL_REQ":
      return { text: "환불 신청", theme: "info" };
    case "CANCEL_PART":
      return { text: "부분 환불", theme: "success" };
    case "CANCEL_REJECT":
      return { text: "환불 거부", theme: "error" };
    case "PAY_REJECT":
      return { text: "결제 취소", theme: "error" };
    case "CANCEL":
      return { text: "전체 환불", theme: "success" };
    case "PAID":
      return { text: "결제 완료", theme: "gray" };
    case "PAY_REQUEST":
      return { text: "결제 대기", theme: "info" };
    case "PAY_REVERT":
      return { text: "결제 취소", theme: "error" };
    default:
      return { text: "결제 요청", theme: "info" };
  }
}
function planPaymentStatusFilter(status) {
  switch (status) {
    case "CANCEL":
    case "REFUND":
      return { text: "환불 완료", theme: "gray" };
    case "REFUND_CANCEL":
      return { text: "환불 취소", theme: "error" };
    case "PAID":
      return { text: "결제 완료", theme: "success" };
    case "CANCEL_PART":
      return { text: "부분 취소", theme: "gray" };
    default:
      return { text: "", theme: "" };
  }
}
function planPaymentTypeFilter(status) {
  switch (status) {
    case "TERMINATE":
      return "해지";
    case "CHANGE":
      return "변경";
    case "NEW":
      return "신규";
    default:
      return "정기 결제";
  }
}
function planPaymentTargetFilter(type) {
  switch (type) {
    case "PLAN":
      return "요금 플랜";
    case "ADDON":
      return "부가 서비스";
    default:
      return "";
  }
}

const completionStatusFilter = (status) => {
  switch (status) {
    case ProgressStatus.COMPLETE_FORCE:
      return {
        text: "관리자 완료",
        theme: "error",
      };
    case ProgressStatus.COMPLETE:
      return {
        text: "진행완료",
        theme: "success",
      };
    case ProgressStatus.PROGRESS:
      return {
        text: "진행중",
        theme: "primary",
      };
    case ProgressStatus.EXCEED:
      return {
        text: "시간초과(이탈)",
        theme: "error",
      };
    case ProgressStatus.NONE:
      return {
        text: "진행전",
        theme: "gray",
      };
  }
  return {
    text: "진행전",
    theme: "gray",
  };
};

const memberConfirmTxt = (status) => {
  switch (status) {
    case MemberChangeType.APPROVAL:
      return {
        title: "승인",
        msg: "참여 신청을 승인하시겠습니까?",
        btnTxtConfirm: "승인하기",
        btnTxtCancel: "아니오",
        icon: "confirm",
      };
    case MemberChangeType.REJECT:
      return {
        title: "거절",
        msg: "참여 신청을 거절하시겠습니까?",
        btnTxtConfirm: "거절하기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
    default:
      return {
        title: "내보내기",
        msg: "내보내기된 회원은 더이상 프로덕트에 참여할 수 없습니다. <br />회원을 내보내시겠습니까?",
        btnTxtConfirm: "내보내기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
  }
};
const userConfirmTxt = (status, length) => {
  switch (status) {
    case MemberChangeType.APPROVAL:
      return {
        title: "가입 승인",
        msg: `${length}명의 신청자를 가입 승인 처리하시겠습니까?`,
        btnTxtConfirm: "승인하기",
        btnTxtCancel: "아니오",
        icon: "confirm",
      };
    case MemberChangeType.REJECT:
      return {
        title: "가입 거절",
        msg: `${length}명의 신청자를 가입 거절 처리하시겠습니까?`,
        btnTxtConfirm: "거절하기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
    default:
      return {
        title: "내보내기",
        msg: "회원을 내보내시겠습니까?",
        btnTxtConfirm: "내보내기",
        btnTxtCancel: "아니오",
        reverse: true,
      };
  }
};

const paymentFilter = (item) => {
  // 정기 결제 && 결제 금액 0 원 > 결제 수단 '-' 표시
  if (item?.purchaseType === "SUBSCRIPTION" && item?.amount === 0) {
    return "-";
  }
  switch (item?.paymentWay) {
    case "NONE":
      return "-";
    case "ETC":
      return "기타 결제";
    case "KAKAO":
      if (item?.pay_method === PayMethod.PACA) {
        return `카카오 페이 (${
          item.orgi?.displayName || item.org?.name || "카드"
        }, ${item?.org?.number || ""})`;
      }
      if (item?.pay_method === PayMethod.PAKM) {
        return `카카오 페이 (머니)`;
      }
      return "카카오 페이";
    case "NAVER":
      if (item?.pay_method === PayMethod.PACA) {
        return `네이버 페이 (${
          item.orgi?.displayName || item.org?.name || "카드"
        }, ${item?.org?.number || ""})`;
      }
      if (item?.pay_method === PayMethod.PANP) {
        return `네이버 페이 (포인트)`;
      }
      return "네이버 페이";
    case "BANK":
      return "실시간 계좌이체";
    case "V_BANK":
      return "가상 계좌이체";
    case "CARD":
      return `(${item.orgi?.displayName || item.org?.name || "카드"}) ${
        item?.org?.number || ""
      }`;
    case "CASH":
      return "현금";
    case "EZWEL":
      return "이지웰";
    default:
      return "-";
  }
};

const pubTargetFilter = (value) => {
  switch (value) {
    case "MEMBER_ONLY":
      return { text: "회원공개", theme: "info" };
    default:
      return { text: "전체공개", theme: "gray" };
  }
};

const snsIconFilter = (type) => {
  switch (type) {
    case "GOOGLE":
      return "Google/20";
    case "FACEBOOK":
      return "Facebook/20";
    case "KAKAO":
      return "Kakao/20";
    case "NAVER":
      return "Naver/20";
    case "APPLE":
      return "Apple/20";
    default:
      return "Email/20";
  }
};

const formatFooterLink = (val) => {
  switch (val) {
    case "HOMEPAGE":
      return "홈페이지";
    case "FACEBOOK":
      return "Facebook";
    case "INSTAGRAM":
      return "Instagram";
    case "NAVER":
      return "Naver Blog";
    case "KAKAO":
      return "Kakao";
  }
  return "";
};

const salesStatusFilter = (type) => {
  switch (type) {
    case "REQUEST":
      return "신청중";
    case "REJECT":
      return "거절";
    case "ENABLE":
      return "승인";
    case "COMPLEMENTATION":
      return "보완 요청";
    default:
      return "미신청";
  }
};

const etcApplyStatusFilter = (type) => {
  switch (type) {
    case "REQUEST":
      return "신청중";
    case "REJECT":
      return "거절";
    case "ENABLE":
      return "승인";
    case "COMPLEMENTATION":
      return "보완 요청";
    default:
      return "미신청";
  }
};

const planCodeFilter = (plan = {}) => {
  const { planCode = "" } = plan;
  switch (planCode) {
    case "FREE":
      return "무료";
    case "ONDEMAND":
      return "온디맨드";
    case "LIGHT":
      return "라이트";
    case "STARTER":
      return "Starter 플랜";
    case "STANDARD":
      return "Standard 플랜";
    case "PREMIUM":
      return "Premium 플랜";
    case "BUSINESS":
      return "Business 플랜";
    case "ENTERPRISE":
      return "Enterprise 플랜";
    default:
      return planCode;
  }
};
const planCycleFilter = (type) => {
  switch (type) {
    case "MONTHLY":
      return "월간";
    case "ANNUALY":
    case "ANNUALLY":
      return "연간";
  }
};
const feedBackStatusFilter = (type) => {
  switch (type) {
    case "READY":
      return { text: "답변 대기", theme: "gray" };
    case "COMPLETE":
      return { text: "답변 완료", theme: "success" };
    default:
      return;
  }
};
const feedBackAskTargetFilter = (type) => {
  switch (type) {
    case "AI":
      return { text: "🖥️ AI", theme: "info" };
    case "PRO":
      return { text: "전문가", theme: "gray" };
    default:
      return;
  }
};
const markStatusFilter = (type) => {
  switch (type) {
    case QuizStatus.PASS:
    case QuizStatus.FAIL:
      return {
        text: "채점완료",
        theme: "success",
      };
    case QuizStatus.SUBMIT:
      return {
        text: "채점필요",
        theme: "error",
      };
    default:
      return;
  }
};

const getAnswerStatusIcon = (question) => {
  const obj = question?.activity || {};
  switch (obj.answerStatus) {
    case "CORRECT":
      return "Circle/Success";
    case "PART":
      return "Triangle/Secondary";
    case "INCORRECT":
      return "Cross/Error";
    case "NONE":
      return "";
  }
  return "";
};

const applicationStatusFilter = (status) => {
  switch (status) {
    case "PICK":
      return {
        text: "선정",
        theme: "success",
      };
    case "DROP":
      return {
        text: "미선정",
        theme: "gray",
      };
    case "SUBMIT":
      return {
        text: "확인필요",
        theme: "error",
      };
    case "HOLD":
      return {
        text: "보완요청",
        theme: "info",
      };
    default:
      return;
  }
};
const questionTypeFilter = (status) => {
  switch (status) {
    case "OBJT":
      return "객관식";
    case "SBJT":
      return "주관식";
    case "DESC":
      return "서술형";
    case "FILE":
      return "파일제출";
    default:
      return "";
  }
};

const addOnTypeFilter = (planCode) => {
  switch (planCode) {
    case "QUIZ":
      return {
        icon: "Service/Quiz",
        text: "퀴즈&과제",
      };
    case "CODING":
      return {
        icon: "Service/Coding",
        text: "코딩 실습",
      };
    case "PRODUCT_BASIC":
      return {
        icon: "Service/ManagementProduct",
        text: "운영 프로덕트",
      };
    case "AI_INSTRUCTOR":
      return {
        icon: "Service/AI",
        text: "AI 조교",
      };
    case "CERTIFICATE":
      return {
        icon: "Service/Diploma",
        text: "수료증",
      };
    case "EXTRA_VOLUME":
      return {
        icon: "Service/Cloud",
        text: "용량 추가",
      };
    case "APPLICATION":
      return {
        icon: "Service/Application",
        text: "신청서",
      };
    case "RESERVATION":
      return {
        icon: "Service/Reservation",
        text: "예약",
      };
    case "ETC_PAY":
      return {
        icon: "Service/EtcPayment",
        text: "기타 결제",
      };
    case "EMAIL_CAMPAIGN":
      return {
        icon: "Service/Email",
        text: "이메일",
      };
    case "PARTNERS":
      return {
        icon: "Service/Partners",
        text: "제휴 마케팅",
      };
    default:
      return {
        icon: "BlankImage",
      };
  }
};

const notSupportedConfirmText = (type) => {
  let contentTypeName = "";
  switch (type) {
    case "QUIZ":
      contentTypeName = "퀴즈&과제";
      break;
    case "CODING":
      contentTypeName = "코딩 실습";
      break;
    case "PRODUCT_BASIC":
      contentTypeName = "운영 프로덕트";
      break;
    case "AI_INSTRUCTOR":
      contentTypeName = "AI 조교";
      break;
    case "CERTIFICATE":
      contentTypeName = "수료증";
      break;
    case "EXTRA_VOLUME":
      contentTypeName = "용량 추가";
      break;
    case "APPLICATION":
      contentTypeName = "신청서";
      break;
    case "RESERVATION":
      contentTypeName = "예약";
      break;
    case "ETC_PAY":
      contentTypeName = "기타 결제";
      break;
    case "PARTNERS":
      contentTypeName = "제휴 마케팅";
      break;
    default:
      break;
  }
  return {
    title: `'${contentTypeName}' 추가 필요`,
    msg: `[ 요금 플랜 / 부가 서비스 > 부가 서비스 ] 에서 '${contentTypeName}' 추가가 필요합니다. 해당 메뉴로 이동하시겠습니까?`,
    btnTxtConfirm: "이동하기",
    btnTxtCancel: "아니오",
    reverse: true,
  };
};
const notSupportedMsg = (type) => {
  const contentTypeName = addOnTypeFilter(type)?.text || "";

  return {
    title: `'${contentTypeName}' 추가 후 이용 가능합니다.`,
    msg: `[ 요금 플랜 / 부가 서비스 > 부가 서비스 ]에서 '${contentTypeName}' 추가를 진행해주세요.`,
  };
};
const productTypeName = (type, short = false) => {
  let name = "";
  switch (type) {
    case "SALES":
      name = short ? "판매" : "판매 프로덕트";
      break;
    case "BASIC":
      name = short ? "운영" : "운영 프로덕트";
      break;
    case "APPLICATION":
      name = "신청서";
      break;
    case "RESERVATION":
      name = "예약";
      break;
    default:
      break;
  }

  return name;
};
const refundToastMsg = (paymentStatus) => {
  let msg = "";
  switch (paymentStatus) {
    case "CANCEL":
      msg = "전체 환불을 완료했습니다.";
      break;
    case "CANCEL_PART":
      msg = "부분 환불을 완료했습니다.";
      break;
    case "CANCEL_REJECT":
      msg = "환불 거부를 완료했습니다.";
      break;
    case "V_BANK_CANCEL_PART":
      msg = "가상계좌 부분 취소를 완료했습니다.";
      break;
    case "V_BANK_CANCEL":
      msg = "가상계좌 결제 취소를 완료했습니다.";
      break;
    default:
      msg = "환불을 완료했습니다.";
      break;
  }

  return msg;
};
const emptyTableMsg = (routeName) => {
  let type = "";
  const regTxt = "등록된";
  const emptyTxt = "없습니다.";
  switch (routeName) {
    case "content":
      type = "콘텐츠가";
      break;
    case "product-sales":
    case "product-basic":
      type = "프로덕트가";
      break;
    case "reservation":
      type = "예약이";
      break;
    case "application":
      type = "신청서가";
      break;
    case "users":
      type = "회원이";
      break;
    case "product-detail-users":
    case "community-dashboard-member":
      type = "참여자가";
      break;
    case "staff":
      type = "직원이";
      break;
    case "community":
      type = "커뮤니티가";
      break;
    case "promotion":
    case "product-detail-promotion":
      type = "프로모션이";
      break;
    case "payment":
      type = "판매 내역이";
      break;
    case "notice":
      type = "공지사항이";
      break;
    case "product-detail-notices":
      type = "안내사항이";
      break;
    case "community-dashboard-notice":
      type = "공지사항이";
      break;
    case "reply":
      type = "댓글이";
      break;
    case "ask":
      type = "문의내역이";
      break;
    case "faq":
      type = "자주묻는질문이";
      break;
    case "plan-histories":
    case "product-detail-payments":
    case "reservation-detail-payments":
      type = "결제 내역이";
      break;
    case "credit-histories":
      type = "크레딧 내역이";
      break;
    case "application-detail-results":
      type = "신청서 목록이";
      break;
    case "product-detail-delegator":
    case "reservation-detail-delegator":
    case "application-detail-delegator":
      type = "담당자가";
      break;
    default:
      type = "데이터가";
      break;
  }
  return `${regTxt} ${type} ${emptyTxt}`;
};

const transferStatusFilter = (status) => {
  switch (status) {
    case "SEND":
      return "발송 완료";
    case "OPEN":
      return "열람";
    case "DELIVERY":
      return "미열람";
    case "CLICK":
      return "클릭";
    case "COMPLAINT":
      return "수신거부";
    case "FAIL":
      return "발송 실패";
    default:
      return "";
  }
};

const failTypeFilter = (status) => {
  switch (status) {
    case "Permanent":
      return "하드 바운스";
    case "Transient":
      return "소프트 바운스";
    case "Undetermined":
      return "알 수 없음";
    default:
      return "발송 실패";
  }
};

const activityTypeFilter = (status) => {
  switch (status) {
    case "ADD":
      return "구독";
    case "SEND":
      return "SEND";
    case "FAIL":
      return "발송 실패";
    case "DELIVERY":
      return "발송 완료";
    case "OPEN":
      return "열람";
    case "CLICK":
      return "클릭";
    case "COMPLAINT":
      return "수신거부";
    case "SIGNUP":
      return "변경";
    default:
      return "";
  }
};

const removeFileExtension = (filename) => {
  return filename?.substr(0, filename.lastIndexOf(".")) || "";
};

/**
 * html 엔터티 코드를 텍스트로 변환해준다.
 * @param text
 * @return {string}
 */
const decodeHTMLEntities = (text) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = text;
  return textarea.value;
};

const mailToTypeFilter = (item, short = false) => {
  const type = item?.toType;
  switch (type) {
    case "GROUP":
      return "회원 그룹";
    case "ADDR_BOOK":
      return "주소록";
    case "PRODUCT_MEMBERS":
      return short ? "프로덕트" : "프로덕트 참여자";
    default:
      return short ? "회원" : "전체 회원";
  }
};

const formatBlockType = (blockType) => {
  switch (blockType) {
    case "TEXT":
      return "텍스트";
    case "BUTTON":
      return "버튼";
    case "IMAGE":
      return "이미지";
    case "BANNER":
      return "배너";
    case "TEXT_BUTTON":
      return "텍스트 + 버튼";
    case "CARD_HORIZONTAL":
      return "가로형 카드";
    case "CARD_VERTICAL":
      return "세로형 카드";
    case "SPACER":
      return "여백";
    case "VIDEO":
      return "비디오";
    case "SNS":
      return "SNS";
    case "DIVIDER":
      return "구분선";
    case "PRODUCT":
      return "프로덕트 리스트";
    case "PRODUCT_CUSTOM":
      return "프로덕트 - 직접 진열";
    case "PRODUCT_AUTO":
      return "프로덕트 - 자동 진열";
    case "COMMUNITY":
      return "커뮤니티 리스트";
    case "COMMUNITY_CUSTOM":
      return "커뮤니티 - 직접 진열";
    case "COMMUNITY_AUTO":
      return "커뮤니티 - 자동 진열";
    case "NOTICE":
      return "사이트 소식 리스트";
    case "NOTICE_CUSTOM":
      return "사이트 소식 - 직접 진열";
    case "NOTICE_AUTO":
      return "사이트 소식 - 자동 진열";
    case "PLAYLIST":
      return "참여현황";
    case "HTML":
      return "코드";
    case "HEADER":
      return "헤더";
    case "FOOTER":
      return "푸터";
    default:
      return "";
  }
};

const formatBlockIcon = (blockType) => {
  switch (blockType) {
    case "TEXT":
      return "Text";
    case "BUTTON":
      return "Button";
    case "IMAGE":
      return "Image";
    case "BANNER":
      return "Banner";
    case "TEXT_BUTTON":
      return "TextButton";
    case "CARD_HORIZONTAL":
      return "Vertical";
    case "CARD_VERTICAL":
      return "Horizontal";
    case "SPACER":
      return "Space";
    case "VIDEO":
      return "Video";
    case "SNS":
      return "SNS";
    case "DIVIDER":
      return "Line";
    case "PRODUCT":
      return "ProductFixed";
    case "PRODUCT_CUSTOM":
    case "PRODUCT_AUTO":
      return "Product";
    case "COMMUNITY":
      return "CommunityFixed";
    case "COMMUNITY_CUSTOM":
    case "COMMUNITY_AUTO":
      return "Community";
    case "NOTICE":
      return "NoticeFixed";
    case "NOTICE_CUSTOM":
    case "NOTICE_AUTO":
      return "Notice";
    case "PLAYLIST":
      return "BlockFlag";
    case "HTML":
      return "Code";
    default:
      return "";
  }
};

const couponPublishFilter = (status) => {
  switch (status) {
    case "AVAILABLE":
      return { text: "발급 중", color: "success" };
    case "READY":
      return { text: "발급 대기", color: "info" };
    case "STOP":
      return { text: "발급 완료", color: "gray" };
    case "FORCE_STOP":
      return { text: "발급 중지", color: "error" };
    default:
      return { text: "-", color: "gray" };
  }
};

const couponTypeFilter = (type) => {
  switch (type) {
    case "SELECT":
      return "대상 지정 발행";
    case "AUTO":
      return "자동 발행";
    case "CODE":
      return "쿠폰 코드 발행";
    case "DOWNLOAD":
      return "다운로드";
    default:
      return "-";
  }
};

const formatPurchaseType = (purchaseType) => {
  switch (purchaseType) {
    case "ONCE":
      return "1회구매";
    case "SUBSCRIPTION":
      return "정기구매";
    default:
      return "-";
  }
};

const formatSubsMonths = (subsMonths) => {
  if (subsMonths === 12) {
    return "1년";
  }
  return `${subsMonths}개월`;
};
const formatSubsMonthsText = (subsMonths) => {
  return `${formatSubsMonths(subsMonths)}마다`;
};

const formatSettlementStatus = (settlementStatus) => {
  switch (settlementStatus) {
    case "STANDBY":
      return "정산 대기";
    case "PAYOUT":
      return "정산 확정";
    case "CANCEL":
      return "정산 취소";
    default:
      return "-";
  }
};
const colorSettlementStatus = (settlementStatus) => {
  switch (settlementStatus) {
    case "STANDBY":
      return "gray";
    case "PAYOUT":
      return "success";
    case "CANCEL":
      return "error";
    default:
      return "gray";
  }
};

const formatSettlementWay = (subsMonth, amount) => {
  if (amount <= 20000) {
    return "일괄 지급";
  }
  switch (subsMonth) {
    case 1:
    case 2:
      return "일괄 지급";
    case 3:
    case 4:
      return "2회 분할 지급";
    case 5:
    case 6:
      return "3회 분할 지급";
    case 12:
      return "6회 분할 지급";
    default:
      return "일괄 지급";
  }
};
const formatSubsStatus = (subsStatus) => {
  switch (subsStatus) {
    case "ACTIVE":
      return "이용중";
    case "PENDING_CANCEL":
      return "해지 예정";
    case "CANCEL":
      return "해지";
    case "FAIL":
      return "결제 실패";
    default:
      return "";
  }
};

const formatProductMemberCompleteDttm = (productMember = {}) => {
  const { progressStatus, forceDttm, completeDttm } = productMember;
  const isComplete = progressStatus === ProgressStatus.COMPLETE;
  if (isComplete && forceDttm && completeDttm) {
    if (new Date(forceDttm) >= new Date(completeDttm)) {
      return getDateFormat(forceDttm);
    } else {
      return getDateFormat(completeDttm);
    }
  } else if (isComplete && (forceDttm || completeDttm)) {
    const dttm = forceDttm || completeDttm;
    return getDateFormat(dttm);
  } else {
    return "-";
  }
};

function formatProductMemberPeriod(member = {}) {
  const { startDttm, endDttm, pauseEndDttm } = member;
  if (!endDttm) {
    return "-";
  }
  if (endDttm === new Date(99999999999999).toJSON()) {
    return "무제한";
  }
  const prev = new Date() < new Date(startDttm) ? startDttm : new Date();
  const left = getDayLeft(prev, endDttm);
  const date = getDateFormat(endDttm);
  const isExpired = new Date(endDttm) - new Date() < 0;
  const isPause = !!pauseEndDttm && new Date(pauseEndDttm) > new Date();
  if (isExpired) {
    return `~ ${date}`;
  }
  if (isPause) {
    return `- (${comma(left)}일)`;
  }
  return `~ ${date} (${comma(left)}일)`;
}

function formatProductMemberStartDttm(startDttm) {
  if (!startDttm || startDttm === new Date(99999999999999).toJSON()) {
    return "-";
  }
  return getDateFormat(startDttm);
}

function badgeProductMemberStatusBasic(memberStatus, endDttm) {
  const isExpired = new Date(endDttm) - new Date() < 0;
  if (memberStatus === "JOIN" && isExpired) {
    return { text: "기간 만료", color: "gray" };
  }
  switch (memberStatus) {
    case MemberStatus.REQUEST:
      return { text: "승인 대기", color: "info" };
    case MemberStatus.JOIN:
      return { text: "참여중", color: "success" };
    case MemberStatus.REJECT:
      return { text: "거절", color: "error" };
    case MemberStatus.CANCEL:
      return { text: "신청 취소", color: "gray" };
    case MemberStatus.DEL:
      return { text: "내보냄", color: "gray" };
    default:
      return { text: "", color: "" };
  }
}

// 프로덕트 수료 여부
function badgeProgressStatusProduct(progressStatus) {
  switch (progressStatus) {
    case ProgressStatus.COMPLETE:
      return { text: "수료", color: "success" };
    default:
      return { text: "미수료", color: "gray" };
  }
}

function formatPurchaseTypeWithSubsMonth(purchaseType, subsMonths) {
  switch (purchaseType) {
    case "ONCE":
      return formatPurchaseType(purchaseType);
    case "SUBSCRIPTION":
      return `${formatPurchaseType(purchaseType)} / ${formatSubsMonths(
        subsMonths || 1
      )}`;
    default:
      return "-";
  }
}
function useStatusFilter(member) {
  const currentDate = new Date();
  if (
    member?.memberStatus === MemberStatus.DEL ||
    (member?.memberStatus === MemberStatus.JOIN &&
      currentDate > new Date(member.endDttm))
  ) {
    return { text: "이용 불가", theme: "error" };
  }
  if (
    member?.memberStatus === MemberStatus.PAY_REQUEST ||
    (member?.memberStatus === MemberStatus.JOIN &&
      currentDate < new Date(member.startDttm))
  ) {
    return { text: "이용 대기", theme: "info" };
  }
  if (
    member?.memberStatus === MemberStatus.JOIN &&
    currentDate >= new Date(member.startDttm) &&
    currentDate <= new Date(member.endDttm)
  ) {
    return { text: "이용 가능", theme: "success" };
  }
  return { text: "-", theme: "gray" };
}

function genderFilter(gender) {
  switch (gender) {
    case "MALE":
      return "남";
    case "FEMALE":
      return "여";
    default:
      return "-";
  }
}

const permissionStatusChip = (yn) => {
  return yn
    ? { text: "허용", color: "success" }
    : { text: "허용 안함", color: "gray" };
};

const productPriceFilter = ({
  price,
  purchaseTypes,
  subsMonths = undefined,
  subsPrice = 0,
  optPrices = [],
  singleLine = false,
}) => {
  if (price === -1) {
    return "-";
  }

  if (purchaseTypes?.length === 2) {
    return singleLine
      ? ` ₩ ${comma(price)} / ₩ ${comma(subsPrice)} (${formatSubsMonths(
          subsMonths
        )})`
      : ` ₩ ${comma(price)} /
            ₩ ${comma(subsPrice)} (${formatSubsMonths(subsMonths)}) `;
  }

  return subsMonths
    ? `₩ ${comma(subsPrice)} (${formatSubsMonths(subsMonths)}) `
    : optPrices?.length > 1
      ? `₩ ${comma(price)} ~`
      : `₩ ${comma(price)}`;
};

const partnerActivityFilter = (status) => {
  switch (status) {
    case "REQUEST":
      return { text: "신청중", color: "info" };
    case "JOIN":
      return { text: "승인", color: "success" };
    case "DISABLED":
      return { text: "정지", color: "error" };
    case "REJECT":
      return { text: "거절", color: "gray" };
    case "CANCEL":
      return { text: "신청 취소", color: "gray" };
    default:
      return { text: "", color: "" };
  }
};
const purchaseTypesFilter = (purchaseTypes = []) => {
  if (
    purchaseTypes.some((v) => v === PurchaseType.ONCE) &&
    purchaseTypes.some((v) => v === PurchaseType.SUBSCRIPTION)
  ) {
    return "1회 / 정기구매";
  }
  if (purchaseTypes.some((v) => v === PurchaseType.ONCE)) {
    return "1회구매";
  }
  if (purchaseTypes.some((v) => v === PurchaseType.SUBSCRIPTION)) {
    return "정기구매";
  }
  return "-";
};

function askStatusUserFilter(status, short = false) {
  switch (status) {
    case "COMPLETE":
      return { text: short ? "완료" : "답변 완료", color: "success" };
    case "CANCEL":
      return { text: short ? "삭제" : "답변 삭제", color: "gray" };
    default:
      return { text: short ? "대기" : "답변 대기", color: "gray" };
  }
}
function askTypeFilter(type) {
  switch (type) {
    case "CAMPUS":
      return "수업문의";
    case "SYSTEM":
      return "기술지원";
    default:
      return "";
  }
}
function askHistoryFilter(type) {
  switch (type) {
    case "CREATE":
      return "등록";
    case "MODIFY":
      return "수정";
    case "DELETE":
      return "삭제";
    default:
      return "";
  }
}

const partnerChangeStatusFilter = (item) => {
  const status = item?.partnerChangeStatus || "";

  switch (status) {
    case "APPROVAL":
      return "승인";
    case "REJECT":
      return "반려";
    default:
      return "-";
  }
};

const partnerTypeFilter = (item) => {
  const bizYn = item?.bizYn;
  const incomeType = item?.incomeType;

  if (bizYn) {
    return "사업자";
  } else {
    return `개인 (${incomeType === "ETC" ? "기타" : "사업"}소득)`;
  }
};
function askStatusFilter(status) {
  switch (status) {
    case AskStatus.STANDBY:
      return { text: "답변 대기", color: "gray" };
    case AskStatus.COMPLETE:
      return { text: "답변 완료", color: "success" };
    case AskStatus.CANCEL:
      return { text: "문의 삭제", color: "gray" };
    default:
      return "";
  }
}

function formatBizNumber(val) {
  let companyNum = val.replace(/[^0-9]/g, "");

  let tempNum = "";

  if (companyNum.length < 4) {
    tempNum = companyNum;
  } else if (companyNum.length < 6) {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    return tempNum;
  } else if (companyNum.length < 11) {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    tempNum += "-";

    tempNum += companyNum.substr(5);

    return tempNum;
  } else {
    tempNum += companyNum.substr(0, 3);

    tempNum += "-";

    tempNum += companyNum.substr(3, 2);

    tempNum += "-";

    tempNum += companyNum.substr(5);
    return tempNum;
  }
}

function encrypt(txt, key = passwordSecretKey) {
  if (!txt) return;
  const result = CryptoJS.AES.encrypt(JSON.stringify(txt), key).toString();
  return result;
}

/**
 * 퍼블릭키로 암호화
 */
async function encryptPublic(data) {
  try {
    // 1. PEM 키를 ArrayBuffer로 변환
    const keyBase64 = atob(kmsPublicKey);
    const keyBuffer = new Uint8Array(keyBase64.length).map((_, i) =>
      keyBase64.charCodeAt(i)
    ).buffer;

    // 2. Web Crypto API에서 퍼블릭 키 가져오기
    const publicKey = await crypto.subtle.importKey(
      "spki", // AWS KMS 공개 키는 spki 형식으로 제공
      keyBuffer,
      {
        name: "RSA-OAEP",
        hash: { name: "SHA-256" }, // RSA-OAEP에서 사용하는 해시 알고리즘
      },
      false, // 추출 불가
      ["encrypt"] // 암호화만 허용
    );

    // 3. 데이터를 암호화
    const encrypted = await crypto.subtle.encrypt(
      {
        name: "RSA-OAEP",
      },
      publicKey,
      new TextEncoder().encode(data) // 데이터를 UTF-8 인코딩
    );

    // 4. 암호화된 데이터를 Base64로 반환
    return btoa(String.fromCharCode(...new Uint8Array(encrypted)));
  } catch (error) {
    console.error("Encryption failed:", error);
    throw error;
  }
}

/**
 * accessKey 기준으로 decrypt.
 * 대칭키로 복호화 필요한 경우 사용한다.
 * ex) 가상계좌결제 환불계좌 복호화
 * @param data
 * @return {*}
 */
function decryptByAccessKey(data) {
  if (!data) return;
  const accessKeyId = store.getters["auth/getAccessKeyId"];
  if (!accessKeyId) return;
  const decrypted = CryptoJS.AES.decrypt(data, accessKeyId).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted;
}

export {
  linkify,
  strip,
  removeAllWhitespace,
  removeEmoji,
  phoneNumberFormat,
  birthFormat,
  snsTypeFilter,
  encryptor,
  roldCdFilter,
  memberStatusName,
  memberStatusChip,
  completionStatusFilter,
  paymentStatusChip,
  memberConfirmTxt,
  userConfirmTxt,
  paymentFilter,
  pubTargetFilter,
  snsIconFilter,
  planCodeFilter,
  planCycleFilter,
  feedBackStatusFilter,
  feedBackAskTargetFilter,
  markStatusFilter,
  getAnswerStatusIcon,
  applicationStatusFilter,
  questionTypeFilter,
  addOnTypeFilter,
  salesStatusFilter,
  planPaymentStatusFilter,
  planPaymentTypeFilter,
  planPaymentTargetFilter,
  etcApplyStatusFilter,
  notSupportedConfirmText,
  notSupportedMsg,
  removeFileExtension,
  decodeHTMLEntities,
  linkifyHtml,
  productTypeName,
  refundToastMsg,
  emptyTableMsg,
  addrSubscribeStatusChip,
  mktYnChip,
  transferStatusFilter,
  failTypeFilter,
  activityTypeFilter,
  mailToTypeFilter,
  formatFooterLink,
  bizNumberFormat,
  formatBlockType,
  couponPublishFilter,
  couponTypeFilter,
  formatPurchaseType,
  formatSubsMonths,
  formatSubsMonthsText,
  formatSettlementStatus,
  colorSettlementStatus,
  formatSettlementWay,
  formatSubsStatus,
  formatProductMemberCompleteDttm,
  formatProductMemberPeriod,
  formatProductMemberStartDttm,
  formatBlockIcon,
  badgeProductMemberStatusBasic,
  badgeProgressStatusProduct,
  formatPurchaseTypeWithSubsMonth,
  useStatusFilter,
  genderFilter,
  permissionStatusChip,
  productPriceFilter,
  partnerActivityFilter,
  purchaseTypesFilter,
  askStatusUserFilter,
  partnerChangeStatusFilter,
  partnerTypeFilter,
  askStatusFilter,
  formatBizNumber,
  askTypeFilter,
  askHistoryFilter,
  encrypt,
  encryptPublic,
  decryptByAccessKey,
};
