<template>
  <div>
    <div ref="profile">
      <profile-image
        v-if="!campusOnly || isAdmin || isPartners || !isMobile"
        @click.native.stop="profileOpen = !profileOpen"
        class="cursor-pointer"
        :imgPath="profilePath"
        alt="profile"
        size="32px"
        size-mobile="32px"
      />
      <div
        @click.stop="profileOpen = !profileOpen"
        class="bottom-nav-item"
        v-else
      >
        <p-icon icon="CircleUser/Black" />
        <span class="text-detail-2-medium black--text text-center"
          >마이페이지</span
        >
      </div>
      <v-fade-transition>
        <div
          v-if="profileOpen"
          style="
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100dvh;
            z-index: 999;
            background: rgba(0, 0, 0, 0.5);
          "
        >
          <div
            @click="profileOpen = false"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
            "
          >
            <v-slide-x-reverse-transition>
              <ProfileMenu
                v-show="profileOpen"
                :profile-path="profilePath"
                key="mb-menu"
                @click.native.stop
              />
            </v-slide-x-reverse-transition>
          </div>
        </div>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProfileImage from "@/components/common/Profile-Image.vue";
import ProfileMenu from "@/components/poin-ui/ProfileMenu.vue";

export default {
  name: "UserProfileMobile",
  components: {
    ProfileImage,
    ProfileMenu,
  },
  data() {
    return {
      open: false,
      loading: false,
      profileOpen: false,
      // profilePath: "",
      timeoutId: () => {},
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/getAuthenticated",
      user: "users/getUser",
      myCampus: "campuses/myCampus",
      isMobile: "common/isMobile",
      count: "notifications/getNewCount",
      userUuid: "users/getUserUuid",
      member: "members/getMemberItem",
      memberId: "members/getMemberId",
      campusId: "campuses/getCampusUuid",
      campusOnly: "common/campusOnly",
      isStaff: "members/isStaff",
    }),
    isAdmin() {
      return this.$route.matched.some((r) => r.meta.isAdmin);
    },
    isPartners() {
      return this.$route.matched.some((r) => r.meta.isPartners);
    },
    countNormalized() {
      if (this.count > 99) {
        return "99+";
      }
      return this.count;
    },
    profilePath() {
      if (this.campusOnly) {
        if (this.memberId) {
          return (
            this.member?.profileImage?.path ||
            "https://file.poincampus.com/assets/ProfileImg@2x.png"
          );
        }
      }
      if (this.userUuid) {
        return (
          this.user?.image?.path ||
          "https://file.poincampus.com/assets/ProfileImg@2x.png"
        );
      }
      return "https://file.poincampus.com/assets/ProfileImg@2x.png";
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.profileOpen = false;
      },
    },
  },
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
      reqGetNotificationCnt: "notifications/reqGetNotificationCnt",
      reqGetUserCampusList: "campuses/reqGetUserCampusList",
    }),
  },
};
</script>

<style lang="scss" scoped>
.bottom-nav-item {
  width: 52px;
  height: 52px;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
}
</style>
