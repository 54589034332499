<script setup>
import { computed, ref, toRefs } from "vue";
import { useMouseInElement } from "@vueuse/core";
import colors from "@/utils/colors";
import { useRoute, useRouter } from "vue-router/composables";
import { useStore } from "@/store";
import { useRouteQuery } from "@/hooks/useRouteQuery";
import { useCampusStore } from "@/stores/campus";
import { isLocal } from "@/config";

const target = ref(null);
const target2 = ref(null);
const { isOutside } = useMouseInElement(target);
const { isOutside: isOutsidee } = useMouseInElement(target2);
const route = useRoute();
const router = useRouter();

const props = defineProps({
  item: {
    type: Object,
  },
  color: {
    type: String,
    default: "#000000",
  },
  accentColor: {
    type: String,
    default: colors.primary.base,
  },
  bgColor: {
    type: String,
    default: "#FFFFFF",
  },
  fontFamily: {
    type: String,
    default: "Pretendard",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const campusStore = useCampusStore();
const { campusUrl, domain } = toRefs(campusStore);
const { item, color, accentColor, bgColor, fontFamily, readonly } =
  toRefs(props);
const emit = defineEmits(["setMenu"]);
const onClick = (item) => {
  if (["DROPDOWN"].includes(item.menuType)) {
    return;
  }
  emit("setMenu", item.id);
};
const onClickSub = (item, parent) => {
  if (["DROPDOWN"].includes(item.menuType)) {
    return;
  }
  emit("setMenu", item.id, parent.id);
};
const getRoute = (item) => {
  switch (item.menuType) {
    case "DROPDOWN":
      return {};
    case "PRODUCT":
      return {
        name: "campus-product-home",
        params: route.params,
      };
    case "COMMUNITY":
      return {
        name: "campus-community-home",
        params: route.params,
      };
    case "NOTICE":
      return {
        name: "campus-notice-home",
        params: route.params,
      };
    default:
      return {
        name: "campus-page",
        params: { ...route.params, slug: item.slug },
      };
  }
};
const getUrl = (url) => {
  if (!url) {
    return "";
  }
  if (url.includes("http")) {
    return url;
  }
  return `https://${url}`;
};
const { setQuery } = useRouteQuery();
const store = useStore();
const getCategories = (menuType) => {
  const list =
    menuType === "PRODUCT"
      ? store.getters["products/getCategories"]
      : store.getters["articles/getCampusNoticeCategoriesUsed"];
  return [{ name: "전체", id: "" }, ...list];
};
const onClickProductSub = (productId, categoryId) => {
  emit("setMenu", productId);
  setQuery("categoryId", categoryId);
};
const isCampusLink = (x) => {
  const url = getUrl(x.url);
  return url && url.includes(campusUrl.value);
};
const getCampusRoute = (x) => {
  if (isCampusLink(x) && x.url) {
    const url = isLocal
      ? getUrl(x.url).replace(campusUrl.value, `/${domain.value}`)
      : getUrl(x.url).replace(campusUrl.value, "");
    const r = router.resolve(url);
    return r;
  }
  return "";
};
</script>

<template>
  <li v-if="!item"></li>
  <li
    v-else-if="!readonly"
    class="nav-item"
    ref="target"
    :class="{ 'is-hover': !isOutside || !isOutsidee }"
  >
    <div class="menu">
      <span class="text" @click.stop="onClick(item)">
        {{ item.name }}
      </span>
      <v-icon
        v-if="item.menuType === 'DROPDOWN' || item.dropdownYn"
        class="sm-icon"
        size="10"
        :color="isOutside && isOutsidee ? color : accentColor"
        >$chevron-down</v-icon
      >
    </div>
    <div class="dropdown" ref="target2" v-if="item.menuType === 'DROPDOWN'">
      <ul class="dropdown-list">
        <li
          class="dropdown-item"
          v-for="child in item.items"
          :key="child.id"
          @click.stop="onClickSub(child, item)"
        >
          <a>
            {{ child.name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="dropdown" ref="target2" v-else-if="item.dropdownYn">
      <ul class="dropdown-list">
        <li
          class="dropdown-item"
          v-for="c in getCategories(item.menuType)"
          :key="c.id || 'ALL'"
          @click.stop="onClickProductSub(item.id, c.id)"
        >
          <a>
            {{ c.name }}
          </a>
        </li>
      </ul>
    </div>
  </li>
  <li v-else-if="item.menuType === 'CUSTOM'" class="nav-item" ref="target">
    <router-link
      class="menu"
      v-if="!!getCampusRoute(item)"
      :to="getCampusRoute(item).location"
      :target="item.newTabYn ? '_blank' : '_self'"
    >
      <span class="text">
        {{ item.name }}
      </span>
    </router-link>
    <a
      v-else
      class="menu"
      :href="getUrl(item.url)"
      :target="item.newTabYn ? '_blank' : '_self'"
    >
      <span class="text">
        {{ item.name }}
      </span>
    </a>
  </li>
  <li
    v-else-if="item.menuType === 'DROPDOWN' || item.dropdownYn"
    ref="target"
    class="nav-item"
    :class="{ 'is-hover': !isOutside || !isOutsidee }"
  >
    <div class="menu">
      <span class="text">
        {{ item.name }}
      </span>
      <v-icon
        class="sm-icon"
        size="10"
        :color="isOutside && isOutsidee ? color : accentColor"
        >$chevron-down</v-icon
      >
    </div>
    <div class="dropdown" ref="target2" v-if="item.menuType === 'DROPDOWN'">
      <ul class="dropdown-list">
        <li
          v-for="subItem in item.items"
          :key="subItem.id"
          class="dropdown-item"
        >
          <template v-if="subItem.menuType === 'CUSTOM'">
            <router-link
              v-if="!!getCampusRoute(subItem)"
              :to="getCampusRoute(subItem).location"
              :target="subItem.newTabYn ? '_blank' : '_self'"
            >
              {{ subItem.name }}
            </router-link>
            <a
              v-else
              :href="getUrl(subItem.url)"
              :target="subItem.newTabYn ? '_blank' : '_self'"
              >{{ subItem.name }}</a
            >
          </template>
          <router-link v-else :to="getRoute(subItem)">
            {{ subItem.name }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="dropdown" ref="target2" v-else-if="item.dropdownYn">
      <ul class="dropdown-list">
        <li
          class="dropdown-item"
          v-for="c in getCategories(item.menuType)"
          :key="c.id || 'ALL'"
        >
          <router-link
            :to="{
              name:
                item.menuType === 'PRODUCT'
                  ? 'campus-product-home'
                  : 'campus-notice-home',
              params: { ...route.params },
              query: { ...(c.id && { categoryId: c.id }) },
            }"
          >
            {{ c.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </li>
  <router-link
    v-else
    :to="getRoute(item)"
    class="nav-item"
    ref="target"
    :class="{ 'is-hover': !isOutside || !isOutsidee }"
  >
    <a class="menu">
      <span class="text">
        {{ item.name }}
      </span>
    </a>
  </router-link>
</template>

<style lang="scss" scoped>
.nav-item {
  position: relative;
  display: flex;
  justify-content: center;
  .menu {
    display: flex;
    align-items: center;
    height: 28px;
    gap: 4px;
    cursor: pointer;
    position: relative;
    color: v-bind(color);
    font-size: 14px;
    &::before {
      content: "";
      position: absolute;
      background-color: v-bind(accentColor);
      width: 100%;
      height: 2px;
      left: 0px;
      bottom: 0;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out;
    }
    .text {
      font-family: v-bind(fontFamily);
    }
  }
  .dropdown {
    display: none;
    position: absolute;
    top: 28px;
    padding-top: 8px;
    opacity: 0;
    z-index: 4;
    animation: 0.3s ease-in-out 0s 1 normal forwards running fadein;
  }
  &:hover {
    .sm-icon {
      transform: rotate(-180deg);
    }
    .menu {
      color: v-bind(accentColor);
      transition: all 0.3s ease-in-out;
      &::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
    .dropdown {
      display: flex;
      flex-direction: column;
      opacity: 1;
    }
  }
}
.dropdown-list {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  width: 180px;
  padding: 12px 0;
  background-color: v-bind(bgColor);
  .dropdown-item {
    cursor: pointer;
    padding: 0 12px;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    @include hover-before;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: v-bind(color);
      font-family: v-bind(fontFamily);
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
