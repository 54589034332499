<template>
  <div class="menu__container">
    <div class="profile__wrap pa-5 w-full">
      <profile-image
        :img-path="profilePath"
        alt="profile"
        size="60px"
        size-mobile="44px"
      />
      <div class="d-flex-column gap-1 w-full overflow-hidden">
        <div class="d-flex align-center gap-2 w-full overflow-hidden">
          <span class="black--text text-body-1-medium single-line">{{
            nickname
          }}</span>
          <p-badge v-if="roleName" :color="roleColor">{{ roleName }}</p-badge>
        </div>
        <div class="d-flex align-center gap-2 w-full overflow-hidden">
          <sns-icon :sns-type="snsType" />
          <span
            class="text-detail-1-regular gray--text text--darken-3 single-line"
            >{{ user.email }}</span
          >
        </div>
      </div>
    </div>

    <!-- 메뉴 비노출 (사이트 > 개설자) -->
    <div
      v-if="campusOnly && isStaff && !isAdmin"
      class="w-full d-flex-column pa-5 gap-6"
    >
      <ul class="information">
        <li>관리자 계정입니다. [캠퍼스 관리]를 통해 설정을 진행해주세요.</li>
      </ul>
      <div class="w-full">
        <p-btn color="gray" outlined small @click="logInOut">로그아웃</p-btn>
      </div>
    </div>

    <!-- 메뉴 노출 -->
    <div class="w-full d-flex-column py-5 gap-6" v-else>
      <!-- <div
        class="d-flex-column w-full px-5"
        v-if="isPartner && !isPartnersView"
      >
        <router-link
          class="w-full"
          :to="{
            name: 'campus-partners-dashboard',
            params: $route.params,
            query: $route.query,
          }"
        >
          <p-btn class="w-full" large color="info" sub>파트너 홈</p-btn>
        </router-link>
      </div> -->

      <div class="d-flex-column gap-5 w-full px-5" v-if="isPartnersView">
        <ul class="information" v-if="isPartnerRequest">
          <li>승인 대기 상태입니다.</li>
        </ul>
        <ul class="information error" v-if="isPartnerReject">
          <li>승인 거절 상태입니다.</li>
        </ul>
        <ul class="information error" v-if="isPartnerDisabled">
          <li>활동정지 상태입니다.</li>
        </ul>
        <router-link
          class="w-full"
          :to="{
            name: 'campus-home',
            params: $route.params,
            query: $route.query,
          }"
        >
          <p-btn class="w-full" large color="gray" outlined>사이트 홈</p-btn>
        </router-link>
      </div>
      <nav class="navigation__wrap">
        <ul class="d-flex flex-column w-full">
          <template v-for="item in navigation">
            <li
              v-if="item.show"
              :key="item.name"
              @click.stop="item.action"
              class="d-flex w-full px-5 text-body-1-medium py-4 align-center cursor-pointer"
            >
              <p-icon class="mr-3" :icon="`${item.icon}/Gray8`" />
              <span>{{ item.label }}</span>
            </li>
          </template>
        </ul>
      </nav>

      <div v-if="isStaff && isAdmin" class="w-full d-flex-column px-5">
        <router-link
          :to="{ ...$route, name: 'campus-admin-recommend' }"
          class="w-full d-flex"
        >
          <p-img
            src="https://file.poincampus.com/assets/images/my-credit.png"
            alt="my-credit"
            border-radius="4"
          />
        </router-link>
      </div>

      <div
        class="w-full d-flex-column"
        v-if="!user?.campusYn && !campusOnly && isMobile"
      >
        <PoinHeaderStartMenu hide-details />
      </div>

      <div class="w-full px-5">
        <p-btn color="gray" outlined small @click="logInOut">로그아웃</p-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { textFormat } from "@/utils";
import { PartnerStatus } from "@/enums/membersEnum";
import globalMixin from "@/mixins/global.vue";
import ProfileImage from "@/components/common/Profile-Image.vue";
import SnsIcon from "@/components/poin-ui/ProfileSnsIcon.vue";
import PoinHeaderStartMenu from "../layout/header/PoinHeaderStartMenu.vue";

export default {
  name: "ProfileMenu",
  mixins: [globalMixin],
  components: {
    ProfileImage,
    SnsIcon,
    PoinHeaderStartMenu,
  },
  props: {
    profilePath: {
      type: String,
      default: "https://file.poincampus.com/assets/ProfileImg@2x.png",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/getAuthenticated",
      user: "users/getUser",
      campusUuid: "campuses/getCampusUuid",
      campusDomain: "campuses/getCampusDomain",
      campusOnly: "common/campusOnly",
      getWindowWidth: "common/getWindowWidth",
      snsTypes: "users/getSnsTypes",
      memberStatus: "members/getMemberStatus",
      member: "members/getMemberItem",
      isPwa: "common/isPwa",
      isStaff: "members/isStaff",
      isMobile: "common/isMobile",
      checkServeYn: "campuses/checkServeYn",
    }),
    nickname() {
      if (this.campusOnly) {
        return this.member?.nickname || this.user?.nickname || this.user.name;
      }
      return this.user.name;
    },
    isAdmin() {
      return this.$route.matched.some((r) => r.meta.isAdmin);
    },
    snsType() {
      return this.snsTypes[0]?.toLowerCase() || "none";
    },
    snsTypesFiltered() {
      if (this.snsTypes && this.snsTypes.length > 0) {
        return {
          txt: textFormat.snsTypeFilter(this.snsTypes[0]),
          img: `/icon/login/${this.snsTypes[0].toLowerCase()}.png`,
        };
      }
      return { txt: "자체가입", img: "/icon/login/none.png" };
    },
    isPartnersView() {
      return this.$route.matched.some((r) => r.meta.isPartners);
    },
    isPartner() {
      return [
        PartnerStatus.JOIN,
        PartnerStatus.REJECT,
        PartnerStatus.REQUEST,
        PartnerStatus.DISABLED,
      ].includes(this.member?.partnerStatus);
    },
    isPartnerRequest() {
      return this.member?.partnerStatus === PartnerStatus.REQUEST;
    },
    isPartnerDisabled() {
      return this.member?.partnerStatus === PartnerStatus.DISABLED;
    },
    isPartnerReject() {
      return this.member?.partnerStatus === PartnerStatus.REJECT;
    },
    isPartnerJoin() {
      return this.member?.partnerStatus === PartnerStatus.JOIN;
    },
    roleName() {
      if (this.memberStatus !== "JOIN") {
        return;
      }
      if (this.isPartnersView) {
        return this.isPartnerJoin || this.isPartnerDisabled ? "파트너" : "";
      }
      return this.member.role?.name;
    },
    roleCode() {
      if (this.memberStatus !== "JOIN") {
        return;
      }
      return this.member.role?.code;
    },
    roleColor() {
      if (this.isPartnersView && this.isPartner) {
        return "primary";
      }
      switch (this.roleCode) {
        case "A":
          return "primary";
        case "B":
          return "info";
        case "C":
          return "info";
        case "D":
          return "gray";
      }
      return "info";
    },
    navigation() {
      return [
        {
          name: "campuses",
          label: "캠퍼스 목록",
          icon: "Browser",
          show: !this.isPwa && !this.campusOnly,
          action: () => {
            if (this.campusOnly) {
              this.$router.push({
                name: "campus-campuses",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else {
              this.$router.push({ name: "campuses" });
            }
          },
        },
        {
          name: "playlist",
          label: "참여현황",
          icon: "Flag",
          show: !this.isAdmin && !this.isPartnersView && this.campusOnly,
          action: () => {
            this.$router.push({
              name: "campus-playlist",
              params: this.$route.params,
              query: this.$route.query,
            });
          },
        },
        {
          name: "cart-list",
          label: "신청현황",
          icon: "FileEdit",
          show: !this.isAdmin && !this.isPartnersView && this.campusOnly,
          action: () => {
            this.$router.push({
              name: "campus-applications",
              params: this.$route.params,
              query: this.$route.query,
            });
          },
        },
        // {
        //   name: "cart-list",
        //   label: "찜 목록",
        //   action: () => {
        //     if (this.campusOnly) {
        //       this.$router.push({
        //         name: "campus-cart",
        //         ...(this.campusDomain && { campusDomain: this.campusDomain }),
        //       });
        //     } else {
        //       this.$router.push({ name: "cart" });
        //     }
        //   },
        // },
        {
          name: "reservation",
          label: "예약현황",
          icon: "CalendarCheck",
          show: !this.isAdmin && !this.isPartnersView && this.campusOnly,
          action: () => {
            this.$router.push({
              name: "campus-reservation",
              params: this.$route.params,
              query: this.$route.query,
            });
          },
        },
        {
          name: "purchases",
          label: "결제내역",
          icon: "Receipt",
          show: !this.isAdmin && !this.isPartnersView && this.campusOnly,
          action: () => {
            this.$router.push({
              name: "campus-purchases",
              params: this.$route.params,
              query: this.$route.query,
            });
          },
        },
        {
          name: "CalendarWon",
          label: "정기구매",
          icon: "CalendarWon",
          show: !this.isAdmin && !this.isPartnersView && this.campusOnly,
          action: () => {
            this.$router.push({
              name: "campus-subscription",
              params: this.$route.params,
              query: this.$route.query,
            });
          },
        },
        {
          name: "coupons",
          label: "쿠폰",
          show: !this.isAdmin && !this.isPartnersView && this.campusOnly,
          icon: "Coupon",
          action: () => {
            this.$router.push({
              name: "campus-coupons",
              params: this.$route.params,
              query: this.$route.query,
            });
          },
        },
        {
          name: "reviews",
          label: "리뷰 내역",
          icon: "Star",
          show: !this.isAdmin && !this.isPartnersView && this.campusOnly,
          action: () => {
            this.$router.push({
              name: "campus-reviews",
              params: this.$route.params,
              query: this.$route.query,
            });
          },
        },

        {
          name: "feedback",
          label: "1:1 피드백",
          icon: "Feedback",
          show: !this.isAdmin && !this.isPartnersView && this.campusOnly,
          action: () => {
            this.$router.push({
              name: "campus-feedback",
              params: this.$route.params,
              query: this.$route.query,
            });
          },
        },
        {
          name: "cards",
          label: "결제 수단",
          icon: "Card",
          show: !this.isAdmin && !this.isPartnersView && this.campusOnly,
          action: () => {
            if (this.campusOnly) {
              this.$router.push({
                name: "campus-cards",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else {
              this.$router.push({ name: "cards" });
            }
          },
        },
        // {
        //   name: "asks",
        //   label: "문의내역",
        //   action: () => {
        //     if (this.campusOnly) {
        //       this.$router.push({
        //         name: "campus-asks",
        //         ...(this.campusDomain && { campusDomain: this.campusDomain }),
        //       });
        //     } else {
        //       this.$router.push({ name: "asks" });
        //     }
        //   },
        // },
        {
          name: "notification",
          label: "알림 설정",
          icon: "Noti",
          show: true,
          action: () => {
            if (this.isAdmin) {
              this.$router.push({
                name: "campus-admin-notification",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else if (this.isPartnersView) {
              this.$router.push({
                name: "campus-partners-notification",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else if (this.campusOnly) {
              this.$router.push({
                name: "campus-notification",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else {
              this.$router.push({ name: "notification" });
            }
          },
        },
        {
          name: "account",
          label: "계정 설정",
          icon: "Setting",
          show: true,
          action: () => {
            if (this.isAdmin) {
              this.$router.push({
                name: "campus-admin-account",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else if (this.isPartnersView) {
              this.$router.push({
                name: "campus-partners-account",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else if (this.campusOnly) {
              this.$router.push({
                name: "campus-account",
                params: this.$route.params,
                query: this.$route.query,
              });
            } else {
              this.$router.push({ name: "account" });
            }
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      signOut: "auth/signOut",
    }),
  },
  mounted() {
    if (this.isMobile) {
      document.body.classList.add("scroll-lock");
    }
  },
  beforeDestroy() {
    if (this.isMobile) {
      document.body.classList.remove("scroll-lock");
    }
  },
};
</script>

<style lang="scss" scoped>
.profile__wrap {
  display: grid;
  gap: 12px;
  grid-template-columns: 60px 1fr;
  align-items: center;
  @media (max-width: $mdBreakPoint) {
    grid-template-columns: 44px 1fr;
  }
}
.menu__container {
  position: absolute;
  top: 56px;
  background: $white;
  border-radius: 4px;
  border: 1px solid $gray3;
  max-width: 360px;
  width: 100%;
  z-index: 12;
  overflow: auto;
  max-height: 90vh;
  @include hide-scrollbar;

  .navigation__wrap {
    width: 100%;
    li:hover {
      background-color: $gray1;
      transition: all 0.3s ease-in-out;
    }
  }
  position: absolute;
  border-radius: 0;
  border: none;
  border-left: 1px solid $gray3;
  top: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
  width: 292px;
  max-width: 100%;
  z-index: 1000;
}
</style>
