import globalStore from "@/store";
import { storeUtils } from "@/utils";

export default (apiInstance) => ({
  /* 캠퍼스 정보 */
  getCampusInfo: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      scope,
      domain,
      headers,
      slug,
    } = payload;

    return apiInstance.campuses.getCampusInfo({
      campusId,
      memberId,
      userId,
      scope,
      domain,
      headers,
      slug,
    });
  },
  reqGetCampusInfo: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      scope,
      domain,
      replace,
      headers,
      slug,
    } = payload;

    const result = await apiInstance.campuses.getCampusInfo({
      campusId,
      memberId,
      userId,
      scope,
      domain,
      headers,
      slug,
    });
    if (result?.success) {
      const key = replace ? "replaceCampusInfo" : "setCampusInfo";
      store.commit(key, result.data);
      if (result.data.designs) {
        store.commit("setDesigns", { campusId, designs: result.data.designs });
      }
      if (result.data?.usedPlan) {
        store.commit("updateSubscription", {
          usedPlan: result.data.usedPlan,
        });
      }
    }
    return result;
  },
  reqGetCampusPolicies: async (store) => {
    const memberId = store.rootGetters["members/getMemberId"];
    const userId = store.rootGetters["users/getUserUuid"];
    const campusId = store.rootGetters["campuses/getCampusUUid"];
    const campusInfo = store.rootGetters["campuses/getCampusInfo"];
    const hasDomain = Boolean(campusInfo?.extDomain || campusInfo?.domain);
    const result = await apiInstance.campuses.getCampusInfo({
      campusId,
      ...(userId && { userId }),
      ...(memberId && { memberId }),
      scope: ["POLICY"],
      ...(hasDomain && { domain: campusInfo?.extDomain || campusInfo?.domain }),
    });
    if (result.success) {
      store.commit("updateCampusInfo", result.data);
    }
    return result;
  },
  reqGetUserCampusList: async (store) => {
    const userId = globalStore.getters["users/getUserUuid"];
    const result = await apiInstance.campuses.getUserCampusList({ userId });
    if (result.success) {
      store.commit("setUserCampusList", result.data);
    }
    return result;
  },
  reqPostCampus: async (store, payload) => {
    const userId = globalStore.getters["users/getUserUuid"];
    const result = await apiInstance.campuses.postCampus({
      ...payload,
      userId,
    });
    if (result.success) {
      store.commit("replaceCampusInfo", result.data);
      globalStore.commit("users/updateUser", { campusYn: true });
    }
    return result;
  },
  reqGetPlan: async (store, payload = {}) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.campuses.getSubscription({
      campusId,
      memberId,
    });
    if (result?.success) {
      store.commit("setSubscription", result.data);
    }
    return result;
  },
  reqPutBasicCampusInfo: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      id = globalStore.getters["campuses/getCampusUuid"],
      name,
      domain,
      extDomain,
      profile,
      campusStatus,
      intro,
      spec,
      favicon,
      ogImg,
      ogTitle,
      ogDesc,
    } = data;

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      id,
      ...(name && { name }),
      ...(domain && { domain }),
      ...(extDomain && { extDomain }),
      ...(profile && { profile }),
      ...(campusStatus && { campusStatus }),
      ...(intro != null && { intro }),
      ...(spec != null && { spec }),
      ...(favicon && { favicon }),
      ...(ogImg != null && { ogImg }),
      ...(ogTitle != null && { ogTitle }),
      ...(ogDesc != null && { ogDesc }),
    });

    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }
    return result;
  },
  reqPutSiteCampusInfo: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      id,
      links,
      workTime,
      contacts,
      mailAccounts,
      objType = "DEFAULT", // ["DEFAULT", "CS_CENTER", "LINK"]
      name = "",
      email = "",
      addr = "",
      bizContact = "",
      bizName = "",
      bizNo = "",
    } = data;
    let payload = {};

    switch (objType) {
      case "CS_CENTER":
        payload = {
          workTime,
          contacts,
          mailAccounts,
        };
        break;
      case "LINK":
        payload = {
          links,
        };
        break;
      case "DEFAULT":
        payload = {
          name,
          email,
          addr,
          bizContact,
          bizName,
          bizNo,
        };
    }

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      SITE: {
        campusesObjType: "SITE",
        campusId,
        ...(id && { id }),
        ...payload,
      },
    });
    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }

    return result;
  },
  reqPutDisplayCampusInfo: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      id,
      images,
      intro,
      times,
    } = data;

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      DISPLAY: {
        campusesObjType: "DISPLAY",
        campusId,
        ...(id && { id }),
        ...(images && { images }),
        ...(intro != null && { intro }),
        ...(times != null && { times }),
      },
    });

    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }

    return result;
  },
  reqPutSectionCampusInfo: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      productSection,
      homeSection,
      id,
    } = data;

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      PRODUCT_SECTIONS: {
        campusesObjType: "PRODUCT_SECTIONS",
        ...(id && { id }),
        campusId,
        ...(homeSection && { homeSection }),
        ...(productSection && { productSection }),
      },
    });

    if (!result.success) {
      storeUtils.putCampusInfoHandleError(result.name);
    }

    return result;
  },
  reqPutPayMethodInfo: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      id,
      v_bankYn,
      etcPayYn,
      naverYn,
      kakaoYn,
      bankYn,
      cardYn,
      etcInform,
    } = data;

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      PAYMETHOD: {
        campusId,
        v_bankYn,
        etcPayYn,
        naverYn,
        kakaoYn,
        bankYn,
        cardYn,
        ...(etcInform && { etcInform }),
      },
    });

    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }

    return result;
  },
  reqPutEtcPayMethod: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      etcCardId,
      etcApplyReason,
      etcApplyStatus,
    } = data;
    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      PAYMETHOD: {
        campusId,
        userId,
        ...(etcCardId && { etcCardId }),
        ...(etcApplyReason && { etcApplyReason }),
        ...(etcApplyStatus && { etcApplyStatus }),
      },
    });

    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }

    return result;
  },
  reqPutSalesCampusInfo: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      id,
      bizYn,
      attaches,
      salesStatus,
      partnerStatus,
      bizName,
      bizNo,
      bizContact,
    } = data;

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      SALES: {
        campusesObjType: "SALES",
        campusId,
        ...(id && { id }),
        bizYn,
        ...(attaches && { attaches }),
        ...(salesStatus && { salesStatus }),
        ...(partnerStatus && { partnerStatus }),
        ...(bizYn && bizName && { bizName }),
        ...(bizYn && bizNo && { bizNo }),
        ...(bizYn && bizContact && { bizContact }),
      },
    });

    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }

    return result;
  },
  reqPutSettingCampusInfo: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      id,
      note,
      admissionType,
      classListAccess,
      admission,
      chTalk,
      partners,
      partnersGuide,
      partnersCaution,
      partnerYn,
      partnerLinkYn,
      partnerLinkRate,
      partnerConsentKey,
      gaId,
      naverAnalyticsId,
      fbPixelId,
      gtmId,
    } = data;

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      SETTING: {
        campusesObjType: "SETTING",
        campusId,
        ...(id && { id }),
        note,
        admissionType,
        classListAccess,
        admission,
        ...(chTalk && { chTalk }),
        ...(gaId !== undefined && { gaId }),
        ...(fbPixelId !== undefined && { fbPixelId }),
        ...(naverAnalyticsId !== undefined && { naverAnalyticsId }),
        ...(gtmId !== undefined && { gtmId }),
        ...(partners && { partners }),
        ...(partnersGuide && { partnersGuide }),
        ...(partnersCaution && { partnersCaution }),
        ...(partnerYn !== undefined && { partnerYn }),
        ...(partnerLinkYn !== undefined && { partnerLinkYn }),
        ...(partnerLinkRate !== undefined && { partnerLinkRate }),
        ...(partnerConsentKey !== undefined && { partnerConsentKey }),
      },
    });
    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }

    return result;
  },
  reqPutSeoCampusInfo: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      id,
      tags,
    } = data;

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      SEO: {
        campusesObjType: "SEO",
        campusId,
        ...(id && { id }),
        tags,
      },
    });

    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }
    return result;
  },
  reqPutFooter: async (store, data) => {
    const result = await store.dispatch("reqPutSiteCampusInfo", data);
    return result;
  },
  reqPutLinks: async (store, data) => {
    const result = await store.dispatch("reqPutSiteCampusInfo", data);
    return result;
  },
  reqPutCustomerService: async (store, data) => {
    const result = await store.dispatch("reqPutSiteCampusInfo", data);
    return result;
  },
  reqPutDesign: async (store, data) => {
    const result = await store.dispatch("reqPutDisplayCampusInfo", data);
    return result;
  },
  reqPutSales: async (store, data) => {
    const result = await store.dispatch("reqPutSalesCampusInfo", data);
    return result;
  },
  reqPutAdmission: async (store, data) => {
    const result = await store.dispatch("reqPutSettingCampusInfo", data);
    return result;
  },
  reqPutSeoHeader: async (store, data) => {
    const result = await store.dispatch("reqPutSeoCampusInfo", data);
    return result;
  },
  reqPutPolicies: async (store, data) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      policyType,
      id,
      conts,
    } = data;

    if (!policyType) {
      return;
    }

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      POLICY: [
        {
          campusesObjType: "POLICY",
          campusId,
          ...(id && { id }),
          policyType,
          conts,
        },
      ],
    });

    if (!result.success) {
      storeUtils.putCampusInfoHandleError(result.name);
    } else {
      store.commit("updateCampusInfo", result.data);
    }
  },
  reqPutMenus: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      menus,
    } = data;

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      MENUS: menus,
    });

    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }

    return result;
  },
  reqPutPopups: async (store, data) => {
    const {
      campusId = globalStore.getters["campuses/getCampusUuid"],
      memberId = globalStore.getters["members/getMemberId"],
      popups,
    } = data;
    const POPUPS = popups?.map((popup) => {
      return {
        ...popup,
        campusId,
        campusesObjType: "POPUPS",
      };
    });

    const result = await apiInstance.campuses.putCampusInfo({
      campusId,
      memberId,
      POPUPS,
    });

    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        campusId,
      });
    } else {
      storeUtils.putCampusInfoHandleError(result.name);
    }

    return result;
  },
  // 캠퍼스 도메인, 외부도메인 중복 체크
  reqDuplicateCheck: async (_, { domain, extDomain }) => {
    if (!domain && !extDomain) {
      return false;
    }
    const result = await apiInstance.campuses.duplicateCheck({
      ...(domain && { domain }),
      ...(extDomain && { extDomain }),
    });

    return result;
  },
  // 캠퍼스설정 > 외부도메인 연동 > 인증서 신규 발급 및 재발급
  reqPostCertificates: async (store, { extDomain, subYn }) => {
    const campusId = globalStore.getters["campuses/getCampusUuid"];
    const memberId = globalStore.getters["members/getMemberId"];
    const result = await apiInstance.campuses.postCertificates({
      campusId,
      memberId,
      extDomain,
      subYn,
    });

    if (result.success) {
      store.commit("setCertificate", result.data);
    }

    return result;
  },
  // 캠퍼스설정 > 외부도메인 연동 > 인증서/배포 상태 확인
  reqGetCertificates: async (store) => {
    const campusId = globalStore.getters["campuses/getCampusUuid"];
    const memberId = globalStore.getters["members/getMemberId"];
    const result = await apiInstance.campuses.getCertificates({
      campusId,
      memberId,
    });

    if (result.success) {
      store.commit("setCertificate", result.data);
    }

    return result;
  },
  // 캠퍼스설정 > 외부도메인 연동 > 연동 해제
  reqDeleteDomains: async (store) => {
    const campusId = globalStore.getters["campuses/getCampusUuid"];
    const memberId = globalStore.getters["members/getMemberId"];
    const result = await apiInstance.campuses.deleteDomains({
      campusId,
      memberId,
    });

    if (result.success) {
      store.commit("clearCertificate");
    } else {
      let msg;
      let fn;
      switch (result.name) {
        case "UNAVAILABLE":
          msg = "해당 기능에 대한 권한이 없습니다.";
          break;
        case "BAD_REQUEST":
          msg = "사이트가 배포 중입니다. 잠시 후, 다시 시도해주세요.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "캠퍼스 또는 외부도메인 정보가 없습니다.";
          break;
        default:
          msg = "데이터를 불러올 수 없습니다.";
          fn = storeUtils.toCampusHome;
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
      if (fn) {
        fn();
      }
    }

    return result;
  },
  reqPostPlans: async (store, payload = {}) => {
    const {
      campusId = store.getters["getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      changeType,
      amount,
      planCycle,
      cardId,
      cardQuota,
      planCode,
    } = payload;
    const result = await apiInstance.campuses.postPlans({
      campusId,
      userId,
      changeType,
      ...(amount && { amount }),
      ...(planCycle && { planCycle }),
      ...(cardId && { cardId }),
      ...(cardQuota && { cardQuota }),
      ...(planCode && { planCode }),
    });
    if (result.success) {
      store.commit("updateCampusInfo", {
        ...result.data,
        PLAN: result.data.plan,
      });
      globalStore.commit("payments/setCheckoutHistory", result.data.history);
    }
    return result;
  },
  reqPutPlans: async (store, payload) => {
    const result = await apiInstance.campuses.putPlans({
      campusId: payload?.campusId || store.getters["getCampusUuid"],
      userId: store.rootGetters["users/getUserUuid"],
    });
    if (result.success) {
      const terminateYn = result.data.terminateYn || false;
      const terminateDttm = result.data.terminateYn || undefined;
      store.commit("updateCampusInfo", {
        plan: { ...result.data, terminateYn, terminateDttm },
        PLAN: { ...result.data, terminateYn, terminateDttm },
      });
    }
    return result;
  },
  reqPutPlansCard: async (store, { cardId }) => {
    const result = await apiInstance.campuses.putPlansCard({
      campusId: store.getters["getCampusUuid"],
      userId: store.rootGetters["users/getUserUuid"],
      cardId,
    });
    return result;
  },
  clearCampusInfo: async (store) => {
    store.commit("clearCampusInfo");
  },
  async reqGetSubscription(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.campuses.getSubscription({
      campusId,
      memberId,
    });
    if (result?.success) {
      store.commit("setSubscription", result.data);
    }
    return result;
  },
  async reqPostSubscription(store, payload) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const {
      changeType,
      planType,
      planCode,
      planCycle,
      credit,
      quota,
      cardId,
      amount,
      cardQuota,
      terminateType,
    } = payload;
    if (!changeType || !planType) {
      return {
        success: false,
        message: "잘못된 요청입니다.",
      };
    }
    const result = await apiInstance.campuses.postSubscription({
      ...(campusId && { campusId }),
      ...(typeof amount === "number" && { amount }),
      ...(planType && { planType }),
      ...(planCycle && { planCycle }),
      ...(cardId && { cardId }),
      ...(quota && { quota }),
      ...(changeType && { changeType }),
      ...(terminateType && { terminateType }),
      ...(credit && { credit }),
      ...(planCode && { planCode }),
      ...(memberId && { memberId }),
      ...(cardQuota && { cardQuota }),
    });
    return result;
  },
  async reqPostSubscriptionPrice(store, payload) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { changeType, planType, planCode, planCycle, quota, credit } =
      payload;
    if (!changeType || !planType) {
      return {
        success: false,
        message: "잘못된 요청입니다.",
      };
    }
    const result = await apiInstance.campuses.postSubscriptionPrice({
      ...(campusId && { campusId }),
      ...(planType && { planType }),
      ...(planCode && { planCode }),
      ...(planCycle && { planCycle }),
      ...(changeType && { changeType }),
      ...(memberId && { memberId }),
      ...(quota && { quota }),
      ...(credit && { credit }),
    });
    return result;
  },
  // DB 업데이트 전 용량 관리
  async checkVolumes(store, inputVolumes = 0) {
    // update USAGE
    await store.dispatch("reqGetCampusInfo");
    const usage = store.getters["getUsage"];
    const usageYn = usage?.volumeUsageYn;
    // 용량 체크하지 않는 캠퍼스일 경우, 진행하지 않는다.
    if (!usageYn) {
      return true;
    }
    const maxVolumes = usage?.maxVolume || 0; // 최대 용량
    const volumes = usage.volume || 0; // 현재 사용량
    const tempVolumes = store.getters["getTempVolumes"] || 0; // 저장하지 않은 용량의 합 (편집 중인 용량의 합)
    const sum = volumes + tempVolumes + inputVolumes; // 현재 사용량 + 저장하지 않은 용량 + 새로 업로드할 파일의 용량
    // console.log(`최대용량: ${maxVolumes}`);
    // console.log(`현재사용량: ${volumes}`);
    // console.log(`저장하지 않은 용량: ${tempVolumes}`);
    // console.log(`업로드할 용량: ${inputVolumes}`);
    // console.log(`업로드할 경우, 사용량 총합: ${sum}`);
    if (sum > maxVolumes) {
      return false;
    }
    // 저장하지 않은 용량에 새로 업로드할 파일 용량을 더해 업데이트
    // console.log(`업로드 성공, 저장예정용량총합: ${inputVolumes + tempVolumes}`);
    store.commit("setTempVolumes", inputVolumes + tempVolumes);
    return true;
  },

  /* 문의 관리 (발신자 메일주소) 이메일 설정 */
  reqPostCsEmail: async (store, payload) => {
    const result = await apiInstance.campuses.sendMailVerification({
      ...payload,
    });
    if (result.success) {
      store.commit("setMailAccounts", result.data);
    }
    return result;
  },

  // 이메일 인증 상태 확인
  reqCsEmailCheck: async (store, payload) => {
    const result = await apiInstance.campuses.getMailVerification({
      ...payload,
    });
    if (result.success) {
      store.commit("setMailAccounts", result.data);
    }
    return result;
  },
  reqDelCsEmail: async (store, data) => {
    const result = await apiInstance.campuses.deleteMailVerification({
      ...data,
    });
    if (result.success) {
      store.commit("clearMailVerification");
      return result.success;
    } else {
      let msg;
      switch (result.name) {
        case "BAD_REQUREST":
          msg = "사이트 정보가 없습니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "존재하지 않는 이메일 주소입니다.";
          break;
        default:
          msg = "다시 시도해주세요.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
  },
  // 이메일 도메인 관리
  reqGetMailDomainVerification: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.campuses.getMailDomainVerification({
      campusId,
      memberId,
    });
    if (result.success) {
      store.commit("setMailDomainAccounts", result.data);
    }
    return result;
  },
  reqPostMailDomainVerification: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      domain,
    } = payload;
    const result = await apiInstance.campuses.postMailDomainVerification({
      campusId,
      memberId,
      domain,
    });
    return result;
  },
  reqDeleteMailDomainVerification: async (store, payload = {}) => {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.campuses.deleteMailDomainVerification({
      campusId,
      memberId,
    });
    if (result.success) {
      store.commit("setMailDomainAccounts", result.data);
      return result;
    } else {
      let msg;
      switch (result.name) {
        case "NOT_SUPPORTED":
          msg = "요금제 기능 미지원";
          break;
        case "UNAUTHORIZE":
          msg = "캠퍼스 관리 권한이 없습니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "대상 도메인이 없습니다.";
          break;
        default:
          msg = "다시 시도해주세요.";
          break;
      }
      globalStore.dispatch("common/setAlert", {
        open: true,
        msg,
      });
    }
  },
});
