// 결제 상태
export const PaymentStatus = {
  PAID: "PAID", // 결제완료
  CANCEL_REQ: "CANCEL_REQ", // 결제취소요청
  CANCEL_PART: "CANCEL_PART", // 부분취소완료
  CANCEL_REJECT: "CANCEL_REJECT", // 취소거부
  CANCEL_REVERT: "CANCEL_REVERT", //취소요청을 취소, PAID상태로 원복
  CANCEL: "CANCEL", // 전체취소완료
  PAY_REQUEST: "PAY_REQUEST", //결제요청(기타, 가상)
  PAY_REJECT: "PAY_REJECT", //결제 거절(기타)
  PAY_REVERT: "PAY_REVERT", //결제 요청 취소 (기타, 가상)
  ETC_REQUEST_REVERT: "ETC_REQUEST_REVERT", // 기타결제 승인 요청 취소
  ETC_REQUEST_REJECT: "ETC_REQUEST_REJECT", // 기타결제 승인 거절
  ETC_REQUEST_APPROVAL: "ETC_REQUEST_APPROVAL", // 기타결제 승인
  ETC_CANCEL: "ETC_CANCEL", // 기타결제 취소
  V_BANK_CANCEL: "V_BANK_CANCEL", // 가상계좌 결제 취소
  V_BANK_CANCEL_PART: "V_BANK_CANCEL_PART", // 가상계좌 결제 부분 취소
  V_BANK_CANCEL_REQ: "V_BANK_CANCEL_REQ", // 가상계좌 결제 취소 요청
  REFUND: "REFUND", // 환불 완료
  REFUND_CANCEL: "REFUND_CANCEL", // 환불 취소
  PAY_CANCEL: "PAY_CANCEL", // PAY_REVERT, PAY_REJECT @jay
  CANCELLATIONS: "CANCELLATIONS", // CANCEL, CANCEL_PART, CANCEL_REJECT @jay
};

// 결제 수단
export const PaymentWay = {
  NONE: "NONE", // 0원 결제내역 발생으로 인해 추가
  CARD: "CARD",
  CASH: "CASH",
  NAVER: "NAVER",
  KAKAO: "KAKAO",
  BANK: "BANK", // 실시간계좌이체
  V_BANK: "V_BANK", // 가상계좌
  ETC: "ETC", // 기타결제
  INTL_CARD: "INTL_CARD", // 해외카드
  NAVER_POINT: "NAVER_POINT",
  KAKAO_MNY: "KAKAO_MNY",
  REG_CARD: "REG_CARD", // 내 결제 카드
  EZWEL: "EZWEL"
};

// 카카오 or 네이버 pay_method
export const PayMethod = {
  PACA: "PACA", // 카드
  PANP: "PANP", // 네이버페이 포인트
  PAKM: "PAKM", // 카카오페이 카카오 머니
};

//가상계좌 환불은행코드
export const BankCdVBankRefund = {
  B279: "DB금융투자(구,동부증권)",
  B218: "KB증권(현대)",
  BK81: "KEB하나은행",
  B247: "NH투자증권(구,우리투자증권)",
  BK23: "SC제일은행",
  B266: "SK증권",
  BK34: "광주은행",
  BK39: "경남은행",
  BK04: "국민은행",
  BK03: "기업은행",
  BK11: "농협",
  BK12: "단위농협",
  BK31: "대구은행",
  B267: "대신증권",
  B238: "미래에셋대우",
  B287: "메리츠",
  BK32: "부산은행",
  BK64: "산림조합",
  BK02: "산업은행",
  B240: "삼성증권",
  BK07: "수협",
  B291: "신영증권",
  BK88: "신한은행",
  B278: "신한금융투자",
  BK48: "신협",
  BK45: "새마을금고",
  BK20: "우리은행",
  BK71: "우체국",
  B209: "유안타증권(구,동양증권)",
  B280: "유진투자증권",
  BK37: "전북은행",
  BK35: "제주은행",
  BK90: "카카오뱅크",
  BK89: "케이뱅크",
  BK92: "토스뱅크",
  B270: "하나증권",
  B262: "하이투자증권",
  B243: "한국투자증권",
  BK27: "한국시티은행",
  B269: "한화증권",
  B263: "현대차증권",
};

export const PurchaseType = {
  ONCE: "ONCE",
  SUBSCRIPTION: "SUBSCRIPTION",
};

export const SettlementStatus = {
  STANDBY: "STANDBY",
  PAYOUT: "PAYOUT",
  CANCEL: "CANCEL",
  CARRY_OVER: "CARRY_OVER",
};
