export default (apiInstance, localStorage, sessionStorage) => ({
  setQuitReason: async (store, payload) => {
    const result = await apiInstance.configure.getConfigure("QUITREASON");
    if (result.success) {
      const { results } = result.data;
      store.commit("setQuitReason", results);
    }
  },
  setProvision: async (store, payload) => {
    const provisionEnum = "PROVISION_CAMPUS";
    const result = await apiInstance.configure.getConfigure(provisionEnum);
    if (result.success) {
      const { results } = result.data;
      store.commit("setProvision", results);
    }
  },
  setReservationConfig: async (store, payload) => {
    const result = await apiInstance.configure.getConfigure("PROVISION_LEARN");
    if (result.success) {
      const { results } = result.data;
      store.commit("setReservationConfig", results);
    }
  },
  setPrivacy: async (store, payload) => {
    const privacyEnum = "PRIVACY_CAMPUS";
    const result = await apiInstance.configure.getConfigure(privacyEnum);
    if (result.success) {
      const { results } = result.data;
      store.commit("setPrivacy", results);
    }
  },
  setProvisionEdu: async (store, payload) => {
    const result = await apiInstance.configure.getConfigure("PROVISION_EDU");
    if (result.success) {
      const { results } = result.data;
      store.commit("setProvisionEdu", results);
    }
  },
  setProvisionPartner: async () => {
    const result =
      await apiInstance.configure.getConfigure("PROVISION_PARTNER");
    return result;
  },
  setTerms: async (store, mode) => {
    const configType =
      mode === "sales" ? "PROVISION_SALES" : "PROVISION_PURCHASE";
    const result = await apiInstance.configure.getConfigure(configType);
    if (result.success) {
      const { results } = result.data;
      store.commit("setTerms", results);
    }
  },
  setConsentEdu: async (store, payload) => {
    const result = await apiInstance.configure.getConfigure("CONSENT_EDU");
    if (result.success) {
      const { results } = result.data;
      store.commit("setConsentEdu", results);
    }
  },
  setCategory: async (store, payload) => {
    const result = await apiInstance.configure.getConfigure("CATEGORY");
    if (result.success) {
      const { results } = result.data;
      store.commit("setCategory", results);
    }
  },
  setCourseQuestion: async (store, payload) => {
    const result = await apiInstance.configure.getConfigure("COURSEQUESTION");
    if (result.success) {
      const { results } = result.data;
      store.commit("setCourseQuestion", results);
    }
  },
  setInstructorGrade: async (store, payload) => {
    const result = await apiInstance.configure.getConfigure("INSTRUCTORGRADE");
    if (result.success) {
      const { results } = result.data;
      store.commit("setInstructorGrade", results);
    }
  },
  setBankOrg: async (store, payload) => {
    const result = await apiInstance.configure.getConfigure("BANKORG");
    if (result.success) {
      const { results } = result.data;
      store.commit("setBankOrg", results);
    }
  },
  setEvents: async (store, viewYn) => {
    let data = {
      configType: "EVENT",
    };
    if (viewYn !== null) {
      data.viewYn = viewYn;
    }
    const result = await apiInstance.configure.getEvents(data);
    if (result.success) {
      const { results } = result.data;
      store.commit("setEvents", results);
    }
  },
  setCupRegisterOpen: async (store) => {
    const result =
      await apiInstance.configure.getConfigure("CUP_REGISTER_OPEN");
    if (result.success) {
      const { results } = result.data;
      store.commit("setCupRegisterOpen", results);
    }
  },
  getCampusPlanConfigure: async (store) => {
    const result = await apiInstance.configure.getConfigure("PLAN_MONTHLY");
    if (result.success) {
      const { results } = result.data;
      store.commit("setCampusPlanConfigure", results);
    }
  },
  getLandingPageLink: async (store, target) => {
    const result = await apiInstance.configure.getConfigure("LANDING");
    if (result.success) {
      const { results } = result.data;
      const matchedConfigItem = results.find(
        (configItem) => configItem.code === target
      );
      if (matchedConfigItem) {
        return matchedConfigItem.url;
      }
      return null;
    }
    return null;
  },
  setSysConfig: async (store, config) => {
    store.commit("setSysConfig", config);
  },
});
