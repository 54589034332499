<script setup>
import { computed, onBeforeUnmount, onMounted } from "vue";
import { useCampusStore } from "@/stores/campus";
import CampusHeaderMobileNavItem from "@/components/layout/header/CampusHeaderMobileNavItem.vue";
import { useRoute } from "vue-router/composables";

const campusStore = useCampusStore();
const menus = computed(() => {
  return campusStore.menus;
});
const items = computed(() => menus.value?.filter((m) => !m.mainYn && m.useYn));
const styles = computed(() => {
  return campusStore.styles;
});
const color = computed(() => styles.value?.headerStyles?.color);
const accentColor = computed(() => styles.value?.headerStyles?.accentColor);
const bgColor = computed(() => styles.value?.headerStyles?.backgroundColor);
const fontFamily = computed(() => styles.value?.headerStyles?.fontFamily);

const route = useRoute();
const design = computed(() => route.name === "campus-admin-design");
onMounted(() => {
  document.body.classList.add("scroll-lock");
});
onBeforeUnmount(() => {
  document.body.classList.remove("scroll-lock");
});
</script>

<template>
  <nav class="navigation" :class="{ design }">
    <ul class="pt-5 pb-15 w-full d-flex-column gap-2">
      <campus-header-mobile-nav-item
        v-for="item in items"
        :key="item.id"
        :item="item"
        :color="color"
        :accentColor="accentColor"
        :bgColor="bgColor"
        :fontFamily="fontFamily"
        readonly
      />
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  width: 292px;
  background-color: v-bind(bgColor);
  z-index: 999;
  overflow: auto;
  padding: 0 var(--gutter);
  @include hide-scrollbar;
}
</style>
