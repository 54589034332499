<template>
  <div
    v-show="!loading"
    class="border-box flex-fill"
    :class="{
      desktop: !isMobile,
      mobile: isMobile,
    }"
  >
    <component v-if="showGlobalMenu" :is="headerComponent" />
    <main
      id="main"
      class="main flex-fill"
      :class="{ 'has-start-btn': showStartButton }"
    >
      <router-view class="router-view" />
    </main>
    <notification />
    <start-button v-if="showStartButton" />
    <top-button :show="showTopBtn" :position="topBtnPosition" />
  </div>
</template>
<script>
import { isEqual } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { domUtils } from "@/utils";
import { useChannelService } from "@/hooks/useChannelService";
import PoinHeader from "@/components/layout/header/PoinHeader.vue";
import PoinHeaderMobile from "@/components/layout/header/PoinHeaderMobile.vue";
import Notification from "@/components/layout/notification/Notification.vue";
import CampusHeader from "@/components/layout/header/CampusHeader.vue";
import CampusHeaderMobile from "@/components/layout/header/CampusHeaderMobile.vue";
import StartButton from "@/components/layout/StartButton.vue";
import TopButton from "@/components/poin-ui/TopButton.vue";
import globalMixin from "@/mixins/global.vue";
const { onBoot, onReset, toggleButton, onUpdate, updateCompleteProductIds } =
  useChannelService();

export default {
  name: "poin-app-web",
  components: {
    Notification,
    PoinHeader,
    PoinHeaderMobile,
    CampusHeader,
    CampusHeaderMobile,
    StartButton,
    TopButton,
  },
  mixins: [globalMixin],
  data() {
    return {
      isShowFooter: true,
      mainElement: null,
      pending: false,
      lastScrollTop: 0,
      delta: 50,
      scrollY: 0,
      drawer: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: "common/isMobile",
      notificationsActive: "notifications/getContainerActive",
      user: "users/getUser",
      authenticated: "auth/getAuthenticated",
      getWindowWidth: "common/getWindowWidth",
      loading: "common/loading",
      showGlobalMenu: "common/showGlobalMenu",
      campusOnly: "common/campusOnly",
      isStaff: "members/isStaff",
      campusInfo: "campuses/getCampusInfo",
      getVideoPanelHeight: "common/getVideoPanelHeight",
      memberId: "members/getMemberId",
      getResetChannelFlag: "common/getResetChannelFlag",
      member: "members/getMemberItem",
      userCompleteIds: "common/getUserCompleteProductIds",
      userId: "users/getUserUuid",
    }),
    headerComponent() {
      if (this.campusOnly) {
        return this.isMobile ? "CampusHeaderMobile" : "CampusHeader";
      }
      return this.isMobile ? "PoinHeaderMobile" : "PoinHeader";
    },
    isAdmin() {
      return this.$route.matched.some((r) => r.meta.isAdmin);
    },
    showTopBtn() {
      if (!this.campusOnly) {
        return true;
      }

      if (
        this.isAdmin &&
        !this.$route.name !== "campus-admin-application-detail-application"
      ) {
        if (
          (this.isMobile && this.scrollY > 1600) ||
          (!this.isMobile && this.scrollY > 2000)
        ) {
          return true;
        }
        return false;
      }
      return (
        this.$route.name &&
        this.$route.name !== "campus-application" &&
        !this.$route.name.includes("playground") &&
        !this.$route.name.includes("quiz")
      );
    },
    showStartButton() {
      // 포인캠퍼스 메인 시작하기 bottomNav 노출 조건
      // 개설된 캠퍼스가 없고 '포인캠퍼스와 함께 시작해보세요!' 버튼 적용 화면
      return (
        !this.user?.campusYn &&
        this.isMobile &&
        ["poin-home", "poin-makers", "poin-supporters"].includes(
          this.$route.name
        )
      );
    },
    regCampusPluginKey() {
      return this.campusInfo?.SETTING?.chTalk?.pluginKey;
    },
    regCampusChMember() {
      return !this.isStaff && this.campusOnly && !!this.regCampusPluginKey;
    },
    excludedRoutes() {
      return [
        "campus-playground",
        "campus-application",
        "campus-admin-application-detail-application",
        "campus-admin-product-detail-quiz",
      ];
    },
    topBtnPosition() {
      // poincampus 메인
      if (!this.campusOnly) {
        if (this.user.campusYn) {
          return this.isMobile ? "20px" : "100px";
        } else {
          return this.isMobile
            ? this.showStartButton
              ? "130px"
              : "20px"
            : "100px";
        }
      }
      // campusOnly && 포인 채널톡, 캠퍼스 채널톡 X
      if (!this.regCampusChMember && !this.isStaff) {
        return this.isMobile ? "76px" : "20px";
      }
      if (!this.isMobile && (this.isStaff || this.regCampusChMember)) {
        return "100px";
      }
      // mobile
      return this.isAdmin ? "80px" : "130px";
    },
  },
  watch: {
    isAdmin: {
      immediate: true,
      deep: true,
      handler(isAdmin) {
        if (this.isMobile) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.toggleMobileButtonClass(isAdmin);
            }, 500);
          });
        }
      },
    },
    $route(to, __) {
      const excludedRoutes = [
        "campus-playground",
        "campus-application",
        "campus-admin-application-detail-application",
        "campus-admin-product-detail-quiz",
      ];

      const yn = !excludedRoutes.includes(to.name);

      toggleButton(yn);
    },
    getResetChannelFlag: {
      immediate: true,
      handler(y) {
        if (y) {
          onReset(true);
          this.resetChannel(false);
        }
      },
    },
    userId: {
      immediate: true,
      handler() {
        if (!this.campusOnly) {
          onReset(true);
        }
      },
    },
    memberId: {
      immediate: true,
      handler() {
        const canUseCampusChTalk = !!this.regCampusPluginKey;

        toggleButton(false);
        onReset();
        this.setUserCompleteProductIds();

        if (this.isStaff) {
          onBoot();
          return;
        }

        if (canUseCampusChTalk) {
          // 개별 캠퍼스 채널톡 연동을 위해 플러그인 키 변경
          onUpdate(this.regCampusPluginKey);
        }
      },
    },
    member: {
      deep: true,
      handler(item) {
        // 멤버 정보의 수료완료한 프로덕트 id 목록
        const completeIds = item?.productMembers?.reduce((acc, cur) => {
          if (cur?.progressStatus === "COMPLETE") {
            acc.push(cur.targetId);
          }
          return acc;
        }, []);

        // 수료 완료 프로덕트 배열이 업데이트된 경우, 채널톡 연동 유저 정보 업데이트
        if (
          completeIds?.length > 0 &&
          !isEqual(completeIds, this.userCompleteIds)
        ) {
          updateCompleteProductIds(completeIds);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      toggleNotificationsActive: "notifications/toggleContainerActive",
    }),
    ...mapMutations({
      resetChannel: "common/resetChannel",
      disconnectPluginKey: "common/disconnectPluginKey",
      setUserCompleteProductIds: "common/setUserCompleteProductIds",
    }),
    toggleMobileButtonClass(onMove) {
      const wrapper = document.querySelector("#ch-plugin-entry > div");
      const shadowRoot = wrapper.shadowRoot;
      const buttonElement = shadowRoot.querySelector("#ch-shadow-root-wrapper");

      if (buttonElement && buttonElement.childNodes.length >= 2) {
        const secondChildElement = buttonElement.childNodes[1];

        if (onMove) {
          secondChildElement.classList.add("ch-btn--mobile");

          const styleElement = document.createElement("style");
          styleElement.textContent = `.ch-btn--mobile { position: fixed !important;
          display: flex;
          flex-wrap: nowrap;
          width: auto;
          overflow: visible;
          background: transparent;
          bottom: 20px !important;
          right: 16px;}`;

          secondChildElement.appendChild(styleElement);
        } else {
          secondChildElement.classList.remove("ch-btn--mobile");
        }
      }
    },

    listenScroll() {
      if (!this.isMobile) {
        return;
      }

      const header = document.querySelector(".mb-header");

      if (!header) {
        return;
      }

      const st = window.pageYOffset;

      if (Math.abs(this.lastScrollTop - st) <= this.delta) {
        return;
      }

      if (st > domUtils.getHeaderHeight() && st < this.lastScrollTop) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }

      this.lastScrollTop = st;
    },
    listenClickEvent(event) {
      if (this.mainElement.contains(event.target)) {
        if (this.notificationsActive) {
          this.toggleNotificationsActive();
        }
      }
    },
    handleScroll() {
      this.scrollY = window.scrollY;
    },
  },
  mounted() {
    this.mainElement = document.getElementById("main");
    this.$el.addEventListener("click", this.listenClickEvent, false);
    this.scrollY = window.scrollY;
    window.addEventListener("scroll", this.handleScroll);

    this.$eventBus.$on("app-message", async (payload) => {
      const { "footer-show": footerShow } = payload;
      if (footerShow !== null && footerShow !== undefined) {
        this.isShowFooter = footerShow;
      }
    });
  },
  beforeDestroy() {
    this.$el.removeEventListener("click", this.listenClickEvent);
    // window.removeEventListener("scroll", this.listenScroll);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
/* @import 'style/import-material-icons.css'; */
.desktop {
  .header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
  }

  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    .router-view {
      width: 100%;
      min-height: var(--main-content-height);
    }
  }
}

.mobile {
  > .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    & + main {
      padding-top: var(--header-height);
    }
  }
  .main.has-start-btn {
    padding-bottom: 56px;
  }
}
</style>
