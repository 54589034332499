import { computed } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useStore } from "@/store";
import * as webview from "@/utils/webview";
import * as path from "@/utils/path";

export function useAuth() {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const isAuthenticated = computed(
    () => store.getters["auth/getAuthenticated"]
  );
  const isPoinWebview = computed(() => store.getters["common/isPoinWebview"]);
  const campusYn = computed(() => store.getters["users/isCampusPlan"]);
  const campusOnly = computed(() => store.getters["common/campusOnly"]);
  const campusDomain = computed(
    () => store.getters["campuses/getCampusDomain"]
  );
  const loginRoute = computed(() => {
    if (campusOnly.value) {
      return {
        name: "campus-login-select",
        params: { campusDomain: campusDomain.value },
        query: route.query,
      };
    }
    return {
      name: "login-select",
      params: {},
      query: route.query,
    };
  });
  const joinRoute = computed(() => {
    if (campusOnly.value) {
      return {
        name: "campus-join-select",
        params: { campusDomain: campusDomain.value },
        query: route.query,
      };
    }
    return {
      name: "join-select",
      params: {},
      query: route.query,
    };
  });

  const clearDiviceId = async () => {
    if (isPoinWebview.value) {
      await store.dispatch("users/reqPutUpdateUsers", { deviceId: "" }); // deviceId 삭제를 위해 호출.
      webview.signOut();
    }
  };

  const signIn = async () => {
    // 로그인 상태일 경우 return
    if (isAuthenticated.value) {
      return;
    }
    // diviceId 초기화
    await clearDiviceId();
    // 현재 경로를 localStorage에 저장
    if (!path.checkLoginPath()) {
      const currentPath = window.location.pathname + window.location.search;
      store.dispatch("common/setLocationPath", currentPath);
    }
    // 로그인 페이지로 이동
    if (route.path !== "/login") {
      router.push(loginRoute.value);
    }
  };
  const signUp = async () => {
    // 로그인 상태일 경우 return
    if (isAuthenticated.value) {
      return;
    }
    router.push(joinRoute.value);
  };

  const signOut = async () => {
    await clearDiviceId();
    await store.dispatch("auth/signOut");

    // 로그아웃 후 300ms 지연
    await new Promise((resolve) => setTimeout(resolve, 300));

    if (route.name === "campus-home") {
      store.dispatch("campuses/reqGetCampusInfo", {
        replace: true,
        slug: "home",
      });
    } else {
      router.push("/");
    }
  };

  return {
    signIn,
    signOut,
    signUp,
    isAuthenticated,
    campusYn,
  };
}
