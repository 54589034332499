<script setup>
import { computed, ref, toRefs, watch } from "vue";
import colors from "@/utils/colors";
import { useRoute, useRouter } from "vue-router/composables";
import { useStore } from "@/store";
import { useCampusStore } from "@/stores/campus";
import { isLocal } from "@/config";

const route = useRoute();
const router = useRouter();
const props = defineProps({
  item: {
    type: Object,
  },
  color: {
    type: String,
    default: "#000000",
  },
  accentColor: {
    type: String,
    default: colors.primary.base,
  },
  bgColor: {
    type: String,
    default: "#FFFFFF",
  },
  fontFamily: {
    type: String,
    default: "Pretendard",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const campusStore = useCampusStore();
const { campusUrl, domain } = toRefs(campusStore);
const { item, color, accentColor, bgColor, fontFamily, readonly } =
  toRefs(props);
const emit = defineEmits(["setMenu"]);
const onClick = (item) => {
  if (["DROPDOWN"].includes(item.menuType)) {
    return;
  }
  emit("setMenu", item.id);
};
const onClickSub = (item, parent) => {
  if (["DROPDOWN"].includes(item.menuType)) {
    return;
  }
  emit("setMenu", item.id, parent.id);
};
const getRoute = (item) => {
  switch (item.menuType) {
    case "DROPDOWN":
      return {};
    case "PRODUCT":
      return {
        name: "campus-product-home",
        params: route.params,
        query: route.query,
      };
    case "COMMUNITY":
      return {
        name: "campus-community-home",
        params: route.params,
        query: route.query,
      };
    case "NOTICE":
      return {
        name: "campus-notice-home",
        params: route.params,
        query: route.query,
      };
    default:
      return {
        name: "campus-page",
        params: { ...route.params, slug: item.slug },
        query: route.query,
      };
  }
};
const getUrl = (url) => {
  if (!url) {
    return "";
  }
  if (url?.includes("http")) {
    return url;
  }
  return `https://${url}`;
};
const expand = ref(false);

const isActive = (cur) => {
  switch (cur.menuType) {
    case "DROPDOWN":
      return false;
    case "PAGE":
      return route.name === "campus-page" && route.params.slug === cur.slug;
    case "PRODUCT":
      return route.name === "campus-product-home";
    case "COMMUNITY":
      return route.name === "campus-community-home";
    case "NOTICE":
      return route.name === "campus-notice-home";
    default:
      return false;
  }
};
const store = useStore();
const getCategories = (menuType) => {
  const list =
    menuType === "PRODUCT"
      ? store.getters["products/getCategories"]
      : store.getters["articles/getCampusNoticeCategoriesUsed"];
  return [{ name: "전체", id: "" }, ...list];
};
const isCampusLink = (x) => {
  const url = getUrl(x.url);
  return url && url.includes(campusUrl.value);
};
const getCampusRoute = (x) => {
  if (isCampusLink(x) && x.url) {
    const url = isLocal
      ? getUrl(x.url).replace(campusUrl.value, `/${domain.value}`)
      : getUrl(x.url).replace(campusUrl.value, "");
    const r = router.resolve(url);
    return r;
  }
  return "";
};
</script>

<template>
  <li v-if="!item"></li>
  <li v-else-if="!readonly" class="nav-item">
    <div class="menu">
      <span class="text" @click.stop="onClick(item)">
        {{ item.name }}
      </span>
      <v-icon
        v-if="item.menuType === 'DROPDOWN'"
        class="sm-icon"
        size="10"
        :color="isOutside && isOutsidee ? color : accentColor"
        >$chevron-down</v-icon
      >
    </div>
    <div class="dropdown" v-if="item.menuType === 'DROPDOWN'">
      <ul class="dropdown-list">
        <li
          class="dropdown-item"
          v-for="child in item.items"
          :key="child.id"
          @click.stop="onClickSub(child, item)"
        >
          <a>
            {{ child.name }}
          </a>
        </li>
      </ul>
    </div>
  </li>
  <li v-else-if="item.menuType === 'CUSTOM'" class="nav-item">
    <router-link
      class="menu"
      v-if="!!getCampusRoute(item)"
      :to="getCampusRoute(item).location"
      :target="item.newTabYn ? '_blank' : '_self'"
    >
      <span class="text" :class="{ 'is-active': isActive(item) }">
        {{ item.name }}
      </span>
    </router-link>
    <a
      v-else
      class="menu"
      :href="getUrl(item.url)"
      :target="item.newTabYn ? '_blank' : '_self'"
    >
      <span class="text" :class="{ 'is-active': isActive(item) }">
        {{ item.name }}
      </span>
    </a>
  </li>
  <li
    v-else-if="item.menuType === 'DROPDOWN' || item.dropdownYn"
    class="nav-item d-flex-column w-full"
    :class="{ 'is-expand': expand }"
  >
    <div class="menu" @click.stop="expand = !expand">
      <span class="text" :class="{ 'is-active': isActive(item) }">
        {{ item.name }}
      </span>
      <v-icon
        class="sm-icon"
        size="10"
        :color="isActive(item) ? accentColor : color"
        >$chevron-down</v-icon
      >
    </div>
    <v-expand-transition>
      <div class="dropdown" v-show="expand">
        <ul class="dropdown-list" v-if="item.menuType === 'DROPDOWN'">
          <li
            v-for="subItem in item.items"
            class="dropdown-item"
            :key="subItem.id"
          >
            <template v-if="subItem.menuType === 'CUSTOM'">
              <router-link
                v-if="!!getCampusRoute(subItem)"
                :to="getCampusRoute(subItem).location"
                :target="subItem.newTabYn ? '_blank' : '_self'"
              >
                <span class="text" :class="{ 'is-active': isActive(item) }">
                  {{ subItem.name }}
                </span>
              </router-link>
              <a
                v-else
                :href="getUrl(subItem.url)"
                :target="subItem.newTabYn ? '_blank' : '_self'"
                >{{ subItem.name }}</a
              >
            </template>
            <router-link
              v-else
              :to="getRoute(subItem)"
              :class="{ 'is-active': isActive(subItem) }"
            >
              {{ subItem.name }}
            </router-link>
          </li>
        </ul>
        <ul class="dropdown-list" v-else-if="item.dropdownYn">
          <li
            class="dropdown-item"
            v-for="c in getCategories(item.menuType)"
            :key="c.id || 'ALL'"
          >
            <router-link
              :to="{
                name:
                  item.menuType === 'PRODUCT'
                    ? 'campus-product-home'
                    : 'campus-notice-home',
                params: { ...route.params },
                query: { ...(c.id && { categoryId: c.id }) },
              }"
            >
              {{ c.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </v-expand-transition>
  </li>
  <router-link v-else :to="getRoute(item)" class="nav-item">
    <div class="menu">
      <span class="text" :class="{ 'is-active': isActive(item) }">
        {{ item.name }}
      </span>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.nav-item {
  position: relative;
  display: flex;
  .menu {
    width: 100%;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    cursor: pointer;
    position: relative;
    color: v-bind(color);
    font-size: 18px;
    .text {
      font-family: v-bind(fontFamily);
      &.is-active {
        color: v-bind(accentColor);
      }
    }
  }
  .dropdown {
    display: flex;
    flex-direction: column;
  }
  &.is-expand {
    .sm-icon {
      transform: rotate(-180deg);
    }
    .menu {
      //color: v-bind(accentColor);
      transition: all 0.3s ease-in-out;
    }
  }
}
.dropdown-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  .dropdown-item {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    position: relative;
    @include hover-before;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: v-bind(color);
      font-family: v-bind(fontFamily);
      &.is-active {
        color: v-bind(accentColor);
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
