export default {
  // 프로덕트 만들기/수정
  productsStaff: {
    lastKey: "",
    items: [],
  },
  productsUser: {
    lastKey: "",
    items: [],
    sections: [],
    homeSection: {},
    productSection: {},
  },
  productsLastKeyUser: "",
  delegators: [],
  newProduct: {
    name: "",
    tags: "",
    price: "1,000",
    refundPolicyId: "",
    period: 0,
    limit: 0,
    startDttm: 99999999999999,
    endDttm: 99999999999999,
    productType: "SALES",
    communityIds: [],
    publishYn: false,
    image: {
      conts: "",
      textColor: "black",
      horizontal: "CENTER",
      nameYn: false,
      items: [
        {
          path: "https://file.poincampus.com/assets/sample/product.png",
          key: "DEFAULT",
        },
      ],
    },
    categoryId: "",
    DESC: {
      links: [""],
      managers: [],
      introduce: "",
    },
    SETTING: {
      options: {
        sequencialYn: false, // @lars migration 오타로 인한 변수명 수정 ..
        video: {
          seekYn: true,
          playbackYn: true,
          multiple: 0,
        },
        audio: {
          seekYn: true,
          playbackYn: true,
          multiple: 0,
        },
      },
      completion: {
        completionType: "AUTO",
        completionUnit: "RATE",
        compRate: 80,
        contents: [
          {
            contentType: "VIDEO",
            compRate: 80,
          },
          {
            contentType: "AUDIO",
            condition: "",
            compRate: 80,
          },
        ],
      },
      certificate: {
        dttmYn: false,
        yn: false,
        startDttm: "",
        seals: [],
        endDttm: "",
        title: "수료증",
        desc: "위 사람은 본 교육과정을 수료하였으므로 이 증서를 수여합니다.",
      },
      pauseYn: false,
      pauseTimes: 0,
      pauseDays: 0,
      reqContsYn: false,
      reqConts: [],
    },
    CONTENTS: [],
    classfiedYn: false,
    joinType: "AUTO",
    DELETE_CONTENTS: [],
    purchaseTypes: [],
  },
  product: {},
  reservation: {}, // 예약 관리 > 편집 데이터
  categories: [],
  localOptions: {
    managersViewYn: false,
    limitYn: false,
    freeYn: false,
    lastProductType: "SALES",
  },
  promotions: [],
  promotion: {},
  promotionLastkey: "",
  checkPrices: {},
  reserveUsers: {
    lastKey: "",
    items: [],
  },
  salesPurchaseType: "ONCE",
};
