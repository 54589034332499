const isDev = import.meta.env.VITE_IS_DEV === "true";
const isLocal = import.meta.env.VITE_IS_LOCAL === "true";

const webBaseUrl = import.meta.env.VITE_WEB_BASE_URL;
const baseUrl = import.meta.env.VITE_BASE_URL;
const baseUrlV2 = import.meta.env.VITE_BASE_URL_V2;

const endpoint = {
  auth: import.meta.env.VITE_AUTH_ENDPOINT,
  users: import.meta.env.VITE_USERS_ENDPOINT,
  configure: import.meta.env.VITE_CONFIGURE_ENDPOINT,
  supports: import.meta.env.VITE_SUPPORTS_ENDPOINT,
  zoom: import.meta.env.VITE_ZOOM_ENDPOINT,
};

const endpointV2 = {
  campuses: import.meta.env.VITE_CAMPUSES_ENDPOINT_V2,
  members: import.meta.env.VITE_MEMBERS_ENDPOINT_V2,
  plans: import.meta.env.VITE_PLANS_ENDPOINT_V2,
  payments: import.meta.env.VITE_PAYMENTS_ENDPOINT_V2,
  admins: import.meta.env.VITE_ADMINS_ENDPOINT_V2,
  credits: import.meta.env.VITE_CREDITS_ENDPOINT_V2,
  vods: import.meta.env.VITE_VODS_ENDPOINT,
  supports: import.meta.env.VITE_SUPPORTS_ENDPOINT_V2,
  notifications: import.meta.env.VITE_NOTIFICATIONS_ENDPOINT_V2,
  products: import.meta.env.VITE_PRODUCTS_ENDPOINT,
  contents: import.meta.env.VITE_CONTENTS_ENDPOINT,
  communities: import.meta.env.VITE_COMMUNITIES_ENDPOINT,
  activities: import.meta.env.VITE_ACTIVITIES_ENDPOINT,
  articles: import.meta.env.VITE_ARTICLES_ENDPOINT,
  transcodes: import.meta.env.VITE_TRANSCODES_ENDPOINT,
  compiles: import.meta.env.VITE_COMPILES_ENDPOINT,
  groups: import.meta.env.VITE_GROUPS_ENDPOINT,
  coupons: import.meta.env.VITE_COUPONS_ENDPOINT,
  campaigns: import.meta.env.VITE_CAMPAIGNS_ENDPOINT,
  histories: import.meta.env.VITE_HISTORIES_ENDPOINT,
  storages: import.meta.env.VITE_STORAGES_ENDPOINT,
};

// Token Refresh 만료 시간 검사(초)
const authExpriedTimeCheck = 60;
const oAuthRedirectUrl = import.meta.env.VITE_OAUTH_REDIRECT_URL;

const vimeo = {
  baseUrl: import.meta.env.VITE_VIMEO_URL,
  accessToken: import.meta.env.VITE_VIMEO_TOKEN,
  deleteAccessToken: import.meta.env.VITE_VIMEO_DELETE_TOKEN,
};

const s3 = {
  attatchmentBucketName: import.meta.env.VITE_S3_ATTACHMENT_BUCKET_NAME,
};

const zoom = {
  clientId: import.meta.env.VITE_ZOOM_CLIENT_ID,
};

const passwordSecretKey = import.meta.env.VITE_PASSWORD_SECRET_KEY;

const videoIframeUrl = import.meta.env.VITE_VIDEO_IFRAME_URL;
const vodJwtSecret = import.meta.env.VITE_VOD_JWT_SECRET;
const vodDistHostname = import.meta.env.VITE_VOD_DEST_HOST;

const kcp = {
  siteCode: import.meta.env.VITE_KCP_SITE_CODE,
  batchSiteCode: import.meta.env.VITE_KCP_SITE_CODE_BATCH,
  retUrl: import.meta.env.VITE_KCP_RET_URL,
  batchRetUrl: import.meta.env.VITE_KCP_BATCH_RET_URL,
  batchGroupId: import.meta.env.VITE_KCP_BATCH_GROUP_ID,
};

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_APP_ID,
};

const firebasePublicVapidKey = import.meta.env.VITE_PUBLIC_VAPID_KEY;

const datadog = {
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
};

const poinChPluginKey = import.meta.env.VITE_CHANNEL_BTN_PLUGIN_KEY;
const partnerConsentKey = import.meta.env.VITE_PARTNER_CONSENT_KEY;

const kmsPublicKey = import.meta.env.VITE_KMS_PUBLIC_KEY;

const awsRegion = import.meta.env.VITE_AWS_REGION;
const userPoolId = import.meta.env.VITE_USER_POOL_ID;

export {
  firebasePublicVapidKey,
  firebaseConfig,
  baseUrl,
  baseUrlV2,
  endpoint,
  endpointV2,
  vimeo,
  webBaseUrl,
  authExpriedTimeCheck,
  s3,
  zoom,
  isDev,
  passwordSecretKey,
  oAuthRedirectUrl,
  videoIframeUrl,
  isLocal,
  vodJwtSecret,
  vodDistHostname,
  kcp,
  datadog,
  poinChPluginKey,
  partnerConsentKey,
  kmsPublicKey,
  awsRegion,
  userPoolId,
};
