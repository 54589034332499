import { PurchaseType } from "@/enums/paymentsEnum";
import { useAlert } from "@/stores/alert";
export default (apiInstance) => ({
  // 요금제 정보 전체 조회
  async reqGetMember(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      headers,
    } = payload;
    const result = await apiInstance.members.getMember({
      campusId,
      userId,
      headers,
    });
    if (result?.success) {
      store.commit("setMemberItem", result.data);
    } else {
      store.commit("clearMemberItem");
    }
    return result;
  },
  async reqGetProductMember(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      productId,
    } = payload;
    if (!userId) {
      return {
        success: true,
      };
    }
    const result = await apiInstance.members.getProductMember({
      campusId,
      userId,
      productId,
    });
    if (result?.success) {
      store.commit("setProductMember", result.data);
    } else {
      store.commit("clearProductMember");
    }
    return result;
  },
  async reqGetRoles(store, payload = {}) {
    const { campusId = store.rootGetters["campuses/getCampusUuid"], id } =
      payload;
    const result = await apiInstance.members.getRoles({
      campusId,
      id,
    });
    if (result.success) {
      store.commit("setRoles", result.data.results);
    }
    return result;
  },
  clearMemberItem(store) {
    store.commit("clearMemberItem");
  },
  async reqPostMemberManagement(store, payload = {}) {
    const {
      changeType,
      productId,
      campusId,
      note,
      conts,
      // TODO: MKT
      // TODO: targets에 mktYn 삽입
      targets,
      userId = store.rootGetters["users/getUserUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    if (!changeType || !(targets?.length > 0)) {
      return;
    }
    const result = await apiInstance.members.postMemberManagement({
      campusId,
      changeType,
      ...(productId && { productId }),
      ...(memberId && { memberId }),
      ...(userId && { userId }),
      ...(note && { note }),
      ...(conts && { conts }),
      ...(targets?.length > 0 && { targets }),
    });
    return result;
  },
  async reqGetCampusMembers(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      keyword,
      memberType = "MEMBER",
      limit = 40,
      code,
      memberStatus,
      inMemberStatus,
      excludeProductId,
      excludeComId,
      excludeGroupSk,
      excludeAddrBookSk,
      groupSk,
      first,
      sort,
      order,
      includeOwner,
      extraPartners,
      productId,
    } = payload;
    if (first) {
      store.commit("clearCampusMembers");
      store.commit("clearCampusMembersLastKey");
      store.commit("setCampusMembersCnt", null);
    }
    const lastKey = store.getters["getCampusMembersLastKey"];
    const result = await apiInstance.members.getCampusMembers({
      campusId,
      limit,
      memberId,
      memberType,
      ...(sort && { sort }),
      ...(order && { order }),
      ...(memberStatus && { memberStatus }),
      ...(inMemberStatus?.length > 0 && { inMemberStatus }),
      ...(userId && { userId }),
      ...(code && { code }),
      ...(excludeProductId && { excludeProductId }),
      ...(excludeComId && { excludeComId }),
      ...(excludeGroupSk && { excludeGroupSk }),
      ...(excludeAddrBookSk && { excludeAddrBookSk }),
      ...(groupSk && { groupSk }),
      ...(lastKey && { lastKey }),
      ...(keyword && { keyword }),
      ...(includeOwner && { includeOwner }),
      ...(extraPartners && { extraPartners }),
      ...(productId && { productId }),
    });
    if (result.success) {
      const {
        results,
        lastKey,
        inviteCnt,
        joinCnt,
        quota,
        requestCnt,
        totalCnt,
      } = result.data;
      store.commit("setCampusMembers", results);
      store.commit("setCampusMembersLastKey", lastKey);
      if (first) {
        store.commit("setCampusMembersCnt", {
          inviteCnt,
          joinCnt,
          quota,
          requestCnt,
          totalCnt,
        });
      }
    } else {
      // cognito error
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg: "시스템 오류입니다.",
        csLink: true,
      });
    }
    return result;
  },
  async reqGetGroupMembers(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      keyword,
      memberType = "MEMBER",
      limit = 40,
      code,
      memberStatus,
      inMemberStatus,
      excludeProductId,
      excludeComId,
      first,
      sort,
      order,
      includeOwner,
      groupSk,
    } = payload;
    if (first) {
      store.commit("clearGroupMembers");
      store.commit("clearGroupMembersLastKey");
      store.commit("setGroupMembersCnt", null);
    }
    const lastKey = store.getters["getGroupMembersLastKey"];
    const result = await apiInstance.members.getCampusMembers({
      campusId,
      ...(!includeOwner && { limit }),
      memberId,
      memberType,
      ...(sort && { sort }),
      ...(order && { order }),
      ...(memberStatus && { memberStatus }),
      ...(inMemberStatus?.length > 0 && { inMemberStatus }),
      ...(userId && { userId }),
      ...(code && { code }),
      ...(excludeProductId && { excludeProductId }),
      ...(excludeComId && { excludeComId }),
      ...(lastKey && { lastKey }),
      ...(keyword && { keyword }),
      ...(includeOwner && { includeOwner }),
      ...(groupSk && { groupSk }),
    });
    if (result.success) {
      const {
        results,
        lastKey,
        inviteCnt,
        joinCnt,
        quota,
        requestCnt,
        totalCnt,
      } = result.data;
      store.commit("setGroupMembers", results);
      store.commit("setGroupMembersLastKey", lastKey);
      if (first) {
        store.commit("setGroupMembersCnt", {
          inviteCnt,
          joinCnt,
          quota,
          requestCnt,
          totalCnt,
        });
      }
    } else {
      // cognito error
      const alert = useAlert();
      alert.open({
        open: true,
        title: "조회 불가",
        msg: "시스템 오류입니다.",
        csLink: true,
      });
    }
    return result;
  },
  updateCampusMembersCnt(store, payload = {}) {
    store.commit("setCampusMembersCnt", {
      ...store.getters["getCampusMembersCnt"],
      ...payload,
    });
  },
  async reqGetCampusMember(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    const result = await apiInstance.members.getCampusMember({
      campusId,
      memberId,
      id,
    });
    if (result.success) {
      store.commit("setCampusMember", result.data);
    } else {
      store.commit("setCampusMember", null);
    }
    return result;
  },
  updateCampusMember(store, payload = {}) {
    store.commit("setCampusMember", payload);
  },
  clearCampusMember(store, payload = {}) {
    store.commit("clearCampusMember", payload);
  },
  // 직원관리 조회
  async reqGetStaff(store, payload = {}) {
    const {
      first = true,
      code,
      limit,
      memberStatus,
      keyword,
      inMemberStatus,
    } = payload;
    if (first) {
      store.commit("resetStaffList");
      store.commit("setStaffLastKey", null);
    }
    const data = {
      campusId: store.rootGetters["campuses/getCampusUuid"],
      lastKey: store.getters["getStaffLastKey"],
      memberType: "STAFF",
      limit,
      ...(code && { code }),
      ...(keyword && { keyword }),
      ...(memberStatus && { memberStatus }),
      ...(inMemberStatus?.length > 0 && { inMemberStatus }),
    };
    const result = await apiInstance.members.getCampusMembers(data);
    if (result.success) {
      const { results, lastKey, inviteCnt } = result.data;
      store.commit("setInviteCnt", inviteCnt);
      store.commit("setStaffList", results);
      store.commit("setStaffLastKey", lastKey);
    }
    return result;
  },
  //수료증 조회
  async reqGetCertificate(store, payload = {}) {
    const {
      userId = store.rootGetters["users/getUserUuid"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      productId,
      memberId,
    } = payload;

    const result = await apiInstance.members.getCertificate({
      campusId,
      userId,
      productId,
      memberId,
    });
    if (result.success) {
      store.commit("setCertificate", result.data);
    } else {
      store.commit("clearCertificate");
    }
    return result;
  },
  // 사용자 > 캠퍼스 멤버 정보 수정
  async reqPutMember(store, payload = {}) {
    const result = await apiInstance.members.putMember({
      id: store.getters["getMemberId"],
      userId: store.rootGetters["users/getUserUuid"],
      ...payload,
    });
    if (result.success) {
      store.commit("updateMemberItem", {
        ...result.data,
        name: result.data?.name || "",
        altName: result.data?.altName || "",
        nickname: result.data?.nickname || "",
        altNickname: result.data?.altNickname || "",
        remark: result.data?.remark || "",
        contact: result.data?.contact || "",
        introduce: result.data?.introduce || "",
        birthdate: result.data?.birthdate || "",
      });
    }
    return result;
  },
  // 사용자 > 캠퍼스 멤버 정보 수정
  async reqPutUser(store, payload = {}) {
    const result = await apiInstance.members.putUser({
      userId: store.rootGetters["users/getUserUuid"],
      ...payload,
    });
    if (result.success) {
      store.dispatch("users/setUser", { user: result.data }, { root: true });
    } else {
      let msg = "시스템 오류입니다.";
      switch (result.name) {
        case "INVALID_PARAMETER":
          msg = "유효하지 않은 데이터입니다.";
          break;
        case "UNAUTHORIZE":
        case "ACCESS_DENIED":
          msg = "권한이 없습니다.";
          break;
        default:
          break;
      }
      const alert = useAlert();
      alert.open({
        open: true,
        title: "수정 불가",
        msg,
      });
    }
    return result;
  },
  async reqPutCampusPush(store, payload = {}) {
    const result = await apiInstance.members.putMember({
      id: store.getters["getMemberId"],
      userId: store.rootGetters["users/getUserUuid"],
      ...payload,
    });
    return result;
  },
  async reqGetMemberByUserId(store, payload = {}) {
    const { userId = store.rootGetters["users/getUserUuid"], campusId } =
      payload;
    const result = await apiInstance.members.getMemberByUserId({
      userId,
      campusId,
    });
    return result;
  },
  async reqGetMembersByUserId(store, payload = {}) {
    const { userId = store.rootGetters["users/getUserUuid"] } = payload;
    const result = await apiInstance.members.getMembersByUserId({
      userId,
    });
    return result;
  },
  async reqGetMembersExcelDownloadKey(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.members.membersExcelDownloadKey({
      campusId,
      memberId,
    });

    return result;
  },
  async postProductsMember(store, payload = {}) {
    const {
      productId,
      changeType,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      userId = store.rootGetters["users/getUserUuid"],
      targets,
      memberId = store.rootGetters["members/getMemberId"],
      multiYn,
      custAdditionals,
    } = payload;
    if (!productId || !changeType) {
      return;
    }
    const result = await apiInstance.members.postProductsMember({
      productId,
      changeType,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(userId && { userId }),
      ...(targets?.length > 0 && { targets }),
      ...(multiYn && { multiYn }),
      ...(custAdditionals?.length > 0 && { custAdditionals }),
    });
    if (result.success) {
      const group = ["BASIC_JOIN_REQUEST", "BASIC_CANCEL", "APPLICATION_JOIN"];
      const mutationName = group.includes(changeType)
        ? "setProductMember"
        : "updateProductsMember";
      store.commit(mutationName, result.data);
    }
    return result;
  },
  async reqGetProductsMembers(store, payload = {}) {
    const {
      keyword,
      memberId = store.rootGetters["members/getMemberId"],
      limit = 40,
      sort,
      progressStatus,
      inProgressStatus,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      order,
      productId,
      memberStatus,
      expireYn,
      lastKey,
      first = true,
      productGroupSk,
      purchaseType,
      statusQuery,
      subsStatus,
    } = payload;
    if (!productId) {
      return;
    }
    if (first) {
      store.commit("clearProductsMembers");
    }
    const result = await apiInstance.members.getProductsMembers({
      productId,
      limit,
      ...(campusId && { campusId }),
      ...(memberId && memberId),
      ...(keyword && { keyword }),
      ...(sort && { sort }),
      ...(progressStatus && { progressStatus }),
      ...(inProgressStatus && { inProgressStatus }),
      ...(order && { order }),
      ...(memberStatus && { memberStatus }),
      ...(lastKey && { lastKey }),
      ...(expireYn && { expireYn }),
      ...(productGroupSk && { productGroupSk }),
      ...(purchaseType && { purchaseType }),
      ...(statusQuery && { statusQuery }),
      ...(subsStatus && { subsStatus }),
    });
    if (result.success) {
      store.commit("updateProductsMembers", result.data);
    }
    return result;
  },
  async reqGetProductsMembersSummary(store, payload = {}) {
    const {
      productId,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    if (!productId) {
      return;
    }
    const result = await apiInstance.members.getProductsMembersSummary({
      productId,
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
    });
    return result;
  },
  async reqGetProductsMember(store, payload = {}) {
    const {
      id,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    if (!id) {
      return;
    }
    const result = await apiInstance.members.getProductsMember({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      id,
    });
    if (result.success) {
      const newContents =
        result?.data?.CONTENTS?.map((content) => {
          const items = content.items?.map((item) => {
            const children = item.items?.map((child) => {
              const descendants = child.items?.map((descendant) => {
                const id = descendant.id;
                const upperId = descendant.upperId;
                return {
                  ...descendant,
                  ...(descendant.syncYn && descendant.origin),
                  ...(id && { id }),
                  ...(upperId && { upperId }),
                };
              });
              const id = child.id;
              const upperId = child.upperId;
              return {
                ...child,
                ...(child.syncYn && child.origin),
                ...(descendants && { items: descendants }),
                ...(id && { id }),
                ...(upperId && { upperId }),
              };
            });
            const id = item.id;
            const upperId = item.upperId;
            return {
              ...item,
              ...(item.syncYn && item.origin),
              ...(children && { items: children }),
              ...(id && { id }),
              ...(upperId && { upperId }),
            };
          });
          const id = content.id;
          const upperId = content.upperId;
          return {
            ...(content.syncYn && content.origin),
            ...content,
            ...(content.syncYn && content.origin),
            ...(items && { items }),
            ...(id && { id }),
            ...(upperId && { upperId }),
          };
        }) || [];
      store.commit("setProductsMember", {
        ...result.data,
        CONTENTS: newContents,
      });
    }
    return result;
  },
  //캠퍼스 관리 > 커뮤니티 운영 > 참여자 관리 > 조회
  async reqGetCommunityMembers(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      keyword,
      limit,
      sort,
      order,
      memberStatus,
      id,
      first,
      productId,
    } = payload;
    if (first) {
      store.commit("clearCommunityMembers");
    }
    const lastKey = store.getters["getCommunityMembersLastKey"];
    const result = await apiInstance.members.getCommunityMembers({
      ...(keyword && { keyword }),
      ...(memberId && { memberId }),
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(campusId && { campusId }),
      ...(order && { order }),
      ...(memberStatus && { memberStatus }),
      ...(lastKey && { lastKey }),
      ...(id && { id }),
      ...(productId && { productId }),
    });
    if (result.success) {
      store.commit("setCommunityMembers", result.data);
    }
    return result;
  },
  // 캠퍼스 관리 > 커뮤니티 > 참여자 관리 > 참여자 상세 조회
  async reqGetCommunityMember(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
      comId,
    } = payload;
    const result = await apiInstance.members.getCommunityMember({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(id && { id }),
      ...(comId && { comId }),
    });
    if (result.success) {
      store.commit("setCommunityMember", result.data);
    }
    return result;
  },
  // 캠퍼스 관리 > 커뮤니티 운영 > 커뮤니티 멤버 관리
  async reqPostCommunityMembers(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      userId = store.rootGetters["users/getUserUuid"],
      productId,
      changeType,
      comId,
      targets,
    } = payload;
    const result = await apiInstance.members.postCommunityMembers({
      ...(productId && { productId }),
      ...(changeType && { changeType }),
      ...(campusId && { campusId }),
      ...(comId && { comId }),
      ...(userId && { userId }),
      ...(targets && { targets }),
      ...(memberId && { memberId }),
    });
    if (result.success) {
      return result;
    }
    return result;
  },
  //회원 상세 > 프로덕트 참여 목록 조회
  async reqGetDetailJoinProducts(store, payload = {}) {
    const {
      id,
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
    } = payload;
    const result = await apiInstance.members.getDetailJoinProducts({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      id,
    });
    if (result.success) {
      store.commit("setJoinProducts", result.data);
    }
    return result;
  },
  async getMySubscriptions(store) {
    const result = await apiInstance.members.getDetailJoinProducts({
      campusId: store.rootGetters["campuses/getCampusUuid"],
      memberId: store.rootGetters["members/getMemberId"],
      id: store.rootGetters["members/getMemberId"],
      purchaseType: PurchaseType.SUBSCRIPTION,
    });
    return result;
  },
  async getMySubscription(store, payload = {}) {
    const { id } = payload;
    const result = await apiInstance.members.getProductsMember({
      campusId: store.rootGetters["campuses/getCampusUuid"],
      memberId: store.rootGetters["members/getMemberId"],
      id,
      subsYn: true,
    });
    return result;
  },
  //프로덕트 운영 상세 > 참여자 관리 > 엑셀 다운로드
  async reqGetProductMembersExcel(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
    } = payload;

    if (!productId) {
      return console.error("productId require");
    }
    const result = await apiInstance.members.getProductJoinMembersExcel({
      ...payload,
      campusId,
      memberId,
    });
    if (!result.success) {
      const alert = useAlert();
      alert.open({
        open: true,
        title: "다운로드 실패",
        msg: "권한이 없습니다.",
      });
    }
    return result?.data;
  },
  async reqGetProductExcelKey(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      keyword,
      sort,
      order,
      progressStatus,
      expireYn,
      fileName,
    } = payload;
    const result = await apiInstance.members.getProductExcelKey({
      ...(campusId && { campusId }),
      ...(memberId && { memberId }),
      ...(productId && { productId }),
      ...(keyword && { keyword }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(progressStatus && { progressStatus }),
      ...(expireYn && { expireYn }),
      ...(fileName && { fileName }),
    });
    return result?.data;
  },
  // 캠퍼스 관리 > 회원 상세 > 참여중 커뮤니티 조회
  async reqGetDetailJoinCommunities(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      id,
    } = payload;
    const result = await apiInstance.members.getDetailJoinCommunities({
      campusId,
      memberId,
      ...(id && { id }),
    });
    if (result.success) {
      store.commit("setJoinCommunities", result.data);
    }
    return result;
  },
  // 권한설정(v2) 권한설정 > 유형별 권한정보 조회
  async reqGetAuthorities(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      staffYn = true,
    } = payload;
    const result = await apiInstance.members.getAuthorities({
      campusId,
      memberId,
      ...(staffYn && { staffYn }),
    });
    if (result.success) {
      store.commit("setAuthorities", result.data);
    }
    return result;
  },
  // 권한설정(v2) 캠퍼스 관리 > 권한설정 > 저장
  async reqPostAuthorities(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      targets,
    } = payload;

    const result = await apiInstance.members.postAuthorities({
      campusId,
      memberId,
      targets,
    });
    return result;
  },
  async reqGetCertsAll(store, payload) {
    const {
      userId = store.rootGetters["users/getUserUuid"],
      campusId = store.rootGetters["campuses/getCampusUuid"],
      productId,
    } = payload;

    const result = await apiInstance.members.getCertsAll({
      userId,
      campusId,
      productId,
    });
    return result;
  },
  setProductMembersEndDttm(store, payload) {
    store.commit("setProductMembersEndDttm", payload);
  },

  async reqGetJoinedMember(store, payload = {}) {
    const { campusId = store.rootGetters["campuses/getCampusUuid"], memberId } =
      payload;
    if (!campusId || !memberId) {
      return {
        success: false,
      };
    }
    const result = await apiInstance.members.GetJoinedMember({
      campusId,
      memberId,
    });
    return result;
  },

  // 일반회원 > 수강일시정지 처리
  async putPauseProductMember(store, payload = {}) {
    const {
      campusId = store.rootGetters["campuses/getCampusUuid"],
      memberId = store.rootGetters["members/getMemberId"],
      productId,
      changeType,
    } = payload;

    if (!productId || !changeType) {
      return { success: false };
    }

    const result = await apiInstance.members.putPauseProductMember({
      campusId,
      memberId,
      productId,
      changeType,
    });

    if (!result?.success) {
      let msg = "다시 시도해주세요.";
      switch (result.name) {
        case "UNAUTHORIZE":
          msg = "권한이 없습니다.";
          break;
        case "INVALID_PARAMETER":
          msg = "필수 항목이 누락되었습니다.";
          break;
        case "NOT_SUPPORTED":
          msg = "참여기간이 무제한인 경우 일시 정지 기능을 사용할 수 없습니다.";
          break;
        case "LIMIT_EXCEED":
          msg = "일시 정지 가능 횟수를 초과하였습니다.";
          break;
        case "REQUEST_NOT_FOUND":
          msg = "프로덕트 멤버 정보 찾을 수 없습니다.";
          break;
        case "UNAVAILABLE":
          msg = "일시 정지 중이 아닙니다.";
          break;
        default:
          msg = "다시 시도해주세요.";
          break;
      }

      const alert = useAlert();
      alert.open({
        open: true,
        title: "일시 정지 불가",
        msg,
      });
    }
    return result;
  },
  async postPartners(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const { changeType, targets } = payload;
    const result = await apiInstance.members.postPartners({
      campusId,
      memberId,
      ...payload,
      ...(changeType && { changeType }),
      ...(targets && {
        targets: targets.map((t) => {
          return {
            campusId,
            ...t,
          };
        }),
      }),
    });
    return result;
  },
  async getPartnersDashboard(store, payload = {}) {
    const campusId = store.rootGetters["campuses/getCampusUuid"];
    const memberId = store.rootGetters["members/getMemberId"];
    const result = await apiInstance.members.getPartnersDashboard({
      campusId,
      memberId,
      ...payload,
    });
    return result;
  },
});
